import { HTMLAttributes, useMemo } from "react";

import {
    APActionBase,
    APActionStatus,
    ReferenceInfoWithCitations,
} from "@/api/types";
import { DocumentReference } from "@/components/action-message/document-reference";
import { DocumentLink } from "@/components/document/document-link";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { getReferenceInfoWithCitations, getResponse } from "@/utils/actions";
import { pluralize } from "@/utils/string-helpers";

interface Props {
    action: APActionBase;
    withLoader?: boolean;
}

export const Evidence = ({ action, withLoader }: Props) => {
    const response = getResponse(action);
    const documents = getReferenceInfoWithCitations(action);
    if (response?.searched_for_evidence === false) return null;

    switch (action.status) {
        case APActionStatus.pending:
            if (withLoader) {
                if (documents.length > 0) {
                    return <ActionMessageEvidence documents={documents} />;
                }
                return <ActionMessageEvidenceSkeleton />;
            }
            return null;
        case APActionStatus.stream_complete:
        case APActionStatus.complete:
            if (response?.searched_for_evidence && documents.length === 0) {
                return <ActionMessageNoEvidence />;
            }
            return <ActionMessageEvidence documents={documents} />;
        case APActionStatus.failed:
        case APActionStatus.error:
            return null;
        default:
            return action.status satisfies never;
    }
};

export const ActionMessageNoEvidence = () => (
    <>
        <p className="col-span-full font-headline text-sm font-bold">Sources</p>
        <p className="italic">No evidence found</p>
    </>
);

export const ActionMessageEvidenceSkeleton = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("space-y-4", className)} {...props}>
        <p className="col-span-full font-headline text-sm font-bold">Sources</p>
        <div className="space-y-3">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-12 w-full" />
        </div>
    </div>
);

const documentHasCitation = ({ citations }: ReferenceInfoWithCitations) =>
    citations !== undefined && citations.length > 0;

export const ActionMessageEvidence = (props: {
    documents: ReferenceInfoWithCitations[];
}) => {
    const documents = useMemo(() => {
        const hasCitation = props.documents.some(documentHasCitation);
        return hasCitation
            ? props.documents.filter(documentHasCitation)
            : props.documents;
    }, [props.documents]);
    return (
        <>
            <p className="col-span-full font-headline text-sm font-bold">
                {pluralize(documents.length, "Source")}
            </p>
            <div
                className={cn(
                    "grid gap-x-4 gap-y-1",
                    documents.length > 1 && "@3xl:grid-cols-2",
                    documents.length > 2 && "@5xl:grid-cols-3",
                )}
            >
                {documents.map(({ info, citations }, i) => (
                    <div className="border-b py-1" key={`${i}_${info.id}`}>
                        <DocumentLink documentID={info.id} asChild>
                            <DocumentReference
                                info={info}
                                citations={citations}
                                className="py-1"
                            />
                        </DocumentLink>
                    </div>
                ))}
            </div>
        </>
    );
};
