import { create } from "zustand";

import { Probe } from "@/api/types";

export enum PageType {
    systemColumns,
    customColumns,
    newColumn,
    editColumn,
}

type Page =
    | { type: Exclude<PageType, PageType.editColumn> }
    | { type: PageType.editColumn; probe: Probe };

type Store = {
    isOpen: boolean;
    page: Page;

    open: () => void;
    close: () => void;
    setOpen: (isOpen: boolean) => void;
    setPage: (type: Exclude<PageType, PageType.editColumn>) => void;
    editProbe: (probe: Probe) => void;
};

export const useColumnSheet = create<Store>((set) => ({
    isOpen: false,
    page: { type: PageType.systemColumns },

    open() {
        set({
            isOpen: true,
            page: {
                type: PageType.systemColumns,
            },
        });
    },

    close() {
        set({ isOpen: false });
    },

    setOpen(isOpen) {
        set((s) => ({
            isOpen,
            page:
                !s.isOpen && isOpen ? { type: PageType.systemColumns } : s.page,
        }));
    },

    setPage(type) {
        set({ page: { type } });
    },

    editProbe(probe) {
        set({
            isOpen: true,
            page: {
                type: PageType.editColumn,
                probe,
            },
        });
    },
}));
