import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PropsWithChildren, ReactNode, useContext } from "react";
import useSWRImmutable from "swr/immutable";

import { StripeContext } from "@/context/stripe-context";
import { useApi } from "@/hooks/use-api";
import { config } from "@/utils/configuration";

const stripePromise = loadStripe(config.stripePublicKey);

const StripeClientSecretContainer = (props: {
    children: (data: { clientSecret: string }) => ReactNode;
}) => {
    const api = useApi();
    const { data: clientSecret } = useSWRImmutable(
        "client_secret",
        async () => await api.fetch_stripe_client_secret(),
        { suspense: true },
    );
    return props.children({ clientSecret });
};

export const StripeElementsForPayments = ({
    clientSecret,
    ...props
}: PropsWithChildren<{ clientSecret: string }>) => {
    return (
        <Elements
            stripe={stripePromise}
            options={{ clientSecret }}
            {...props}
        />
    );
};

export const StripeElements = ({
    paymentIntent,
    ...props
}: PropsWithChildren<{ paymentIntent?: boolean }>) => {
    const { clientSecret } = useContext(StripeContext);
    if (paymentIntent) {
        if (clientSecret) {
            return (
                <StripeElementsForPayments
                    clientSecret={clientSecret}
                    {...props}
                />
            );
        }
        return (
            <StripeClientSecretContainer>
                {({ clientSecret }) => (
                    <StripeElementsForPayments
                        clientSecret={clientSecret}
                        {...props}
                    />
                )}
            </StripeClientSecretContainer>
        );
    }
    return <Elements stripe={stripePromise} {...props} />;
};
