import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

import { useFavicon } from "@/hooks/use-favicon";
import { config } from "@/utils/configuration";

const getFavicon = (theme: string | undefined): string => {
    if (config.env === "staging") return "/logo_staging.svg";
    if (config.isDev) return "/logo_dev.svg";
    return theme === "dark" ? "/logo_white.svg" : "/logo_black.svg";
};

export const Favicon = () => {
    const { resolvedTheme } = useTheme();
    const [favicon, setFavicon] = useState(getFavicon(resolvedTheme));
    useFavicon(favicon);

    useEffect(() => setFavicon(getFavicon(resolvedTheme)), [resolvedTheme]);

    return null;
};
