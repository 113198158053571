import { useContext } from "react";
import { Link } from "react-router-dom";

import { ErrorAlert } from "@/components/error-alert";
import { LoginForm } from "@/components/forms/login";
import { Button } from "@/components/ui/button";
import { AppContext } from "@/context/app-context";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";

export const LoginRoute = () => {
    usePageTitle("Login");
    const { api, login } = useContext(AppContext);
    const action = useAsyncState(async (email: string, password: string) => {
        const token = await api.login_user(email, password);
        login(token);
    });

    return (
        <>
            <div className="space-y-4">
                <h2 className="text-center font-headline text-2xl font-semibold">
                    Welcome Back
                </h2>
                <p className="text-center text-sm font-light">
                    Enter your email and password to access your account
                </p>
            </div>
            {action.error && <ErrorAlert error={action.error} />}
            <LoginForm action={action} />
            <div className="text-center">
                <Button variant="link" asChild>
                    <Link to="/forgot-password">Forgot password?</Link>
                </Button>
            </div>
        </>
    );
};
