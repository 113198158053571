import { Slot } from "@radix-ui/react-slot";
import {
    createContext,
    HTMLAttributes,
    PropsWithChildren,
    SyntheticEvent,
    useCallback,
    useContext,
} from "react";
import { useNavigate } from "react-router-dom";

import { UUID } from "@/api/types";

interface Props extends HTMLAttributes<HTMLDivElement> {
    documentID: UUID;
    urlPrefix?: string;
    asChild?: boolean;
}

const sanitizeUrlPrefix = (prefix: string | undefined): string => {
    if (prefix == undefined) return "";
    if (prefix.endsWith("/")) return prefix;
    return prefix + "/";
};

type TDocumentLinkContext = {
    onNavigate: (documentID: UUID, urlPrefix?: string) => void;
};
export const DocumentLinkContext = createContext<TDocumentLinkContext | null>(
    null,
);

export const DocumentLink = ({
    documentID,
    urlPrefix,
    asChild = false,
    ...props
}: PropsWithChildren<Props>) => {
    const Comp = asChild ? Slot : "div";
    const navigate = useNavigate();
    const ctx = useContext(DocumentLinkContext);

    const handleClick = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            if (ctx) {
                ctx.onNavigate(documentID, urlPrefix);
            } else {
                navigate(
                    `${sanitizeUrlPrefix(urlPrefix)}document/${documentID}`,
                );
            }
        },
        [ctx, navigate, urlPrefix, documentID],
    );

    return <Comp onClick={handleClick} {...props} />;
};
