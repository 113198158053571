import {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";
import { KeyedMutator } from "swr";
import useSWRImmutable from "swr/immutable";
import { v4 as uuidv4 } from "uuid";

import { PaymentMethod } from "@/api/types";
import { SubscriptionDetails } from "@/components/billing/subscription-details";
import { SubscriptionContainer } from "@/container/paywall/subscription";
import { BillingInformation } from "@/container/settings/billing/billing-information";
import { Invoices } from "@/container/settings/billing/invoices";
import { PaymentMethods } from "@/container/settings/billing/payment-methods";
import { useApi } from "@/hooks/use-api";
import { usePageTitle } from "@/hooks/use-page-title";
import { useUser } from "@/hooks/use-user";

type TContext = {
    defaultPaymentMethod: PaymentMethod | null;
    mutateDefaultPaymentMethod: KeyedMutator<PaymentMethod>;
};

export const BillingSettingsContext = createContext<TContext>({
    defaultPaymentMethod: null,
    mutateDefaultPaymentMethod: async () => undefined,
});

const BillingSettingsContextProvider = (props: PropsWithChildren) => {
    const api = useApi();
    const { data, mutate: mutateDefaultPaymentMethod } = useSWRImmutable(
        "default_payment_method_provider",
        async () => await api.fetch_default_payment_method(),
    );
    return (
        <BillingSettingsContext.Provider
            value={{
                defaultPaymentMethod: data ?? null,
                mutateDefaultPaymentMethod,
            }}
            {...props}
        />
    );
};

export const BillingSettings = () => {
    usePageTitle(["Billing", "Settings"]);
    const user = useUser();
    const [fetchKey, setFetchKey] = useState<string | null>(null);

    useEffect(() => {
        setFetchKey(uuidv4());
    }, [setFetchKey]);

    const onPaymentSuccess = useCallback(() => {
        setFetchKey(uuidv4());
    }, [setFetchKey]);

    return (
        <BillingSettingsContextProvider>
            <div className="col-span-2 space-y-8 lg:col-span-3">
                <h1 className="text-headline text-2xl font-medium">Billing</h1>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Current Plan
                    </h1>
                    {fetchKey && (
                        <SubscriptionContainer fetchKey={fetchKey}>
                            {({ subscription }) => (
                                <SubscriptionDetails
                                    user={user}
                                    subscription={subscription}
                                />
                            )}
                        </SubscriptionContainer>
                    )}
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Billing Information
                    </h1>
                    <BillingInformation />
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Payment Methods
                    </h1>
                    <PaymentMethods />
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Invoices
                    </h1>
                    <Invoices onPaymentSuccess={onPaymentSuccess} />
                </div>
            </div>
        </BillingSettingsContextProvider>
    );
};
