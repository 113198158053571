import {
    Probe,
    GenericFindingContent,
    FindingContentType,
    AsyncFindingGroup,
    DocumentFindingGroupBase,
    AsyncFindingGroupBase,
    DocumentFindingGroup,
    FindingGroupInfo,
    FindingGroupType,
} from "@/api/types";
import { AsyncState } from "@/utils/async-value";
import { nonNull } from "@/utils/fn";

export const getProbeTitle = (probe?: Probe | null): string => {
    if (!probe) return "Custom Column";
    return probe.name;
};

export const getRawFindingContent = (
    finding: GenericFindingContent,
): string | undefined => {
    switch (finding.content_type) {
        case FindingContentType.boolean:
            return `${finding.value ? "YES" : "NO"}\n${finding.text}`;
        case FindingContentType.full_text:
        case FindingContentType.long_text:
        case FindingContentType.short_text:
        case FindingContentType.list_item:
            return finding.text;
        case FindingContentType.titled_long_text:
            return `${finding.title}\n\n${finding.text}`;
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return undefined;
    }
};

export const createAsyncFindingGroup = (
    finding_group: FindingGroupInfo | DocumentFindingGroup,
): AsyncFindingGroup => ({
    ...finding_group,
    findings:
        "findings" in finding_group && finding_group.findings.length > 0
            ? { state: AsyncState.success, value: finding_group.findings }
            : { state: AsyncState.initial },
});

export const createAsyncDocumentFindingGroup = <T extends FindingGroupType>(
    finding_group: DocumentFindingGroupBase<T>,
): AsyncFindingGroupBase<T> => ({
    ...finding_group,
    findings: { state: AsyncState.success, value: finding_group.findings },
});

export const getFindingGroup = (
    async_finding_group: AsyncFindingGroup,
): DocumentFindingGroup => ({
    ...async_finding_group,
    findings:
        async_finding_group.findings.state === AsyncState.success
            ? async_finding_group.findings.value
            : [],
});

export const maybeGetFindingGroup = (
    async_finding_group: AsyncFindingGroup | null | undefined,
): DocumentFindingGroup | null | undefined =>
    nonNull(async_finding_group)
        ? getFindingGroup(async_finding_group)
        : async_finding_group;

export const isExecutiveSummary = (fg: AsyncFindingGroup): boolean =>
    fg.type === FindingGroupType.executive_summary;

export const isRisks = (fg: AsyncFindingGroup): boolean =>
    fg.type === FindingGroupType.risks;

export const isOpportunities = (fg: AsyncFindingGroup): boolean =>
    fg.type === FindingGroupType.opportunities;
