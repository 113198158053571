import { CircleCheckBig, LetterText, List, LucideProps } from "lucide-react";

import { ProbeType } from "@/api/types";
import { cn } from "@/lib/utils";

export const ProbeTypeIcon = ({
    type,
    className,
    ...props
}: LucideProps & { type: ProbeType }) => {
    switch (type) {
        case ProbeType.text:
        case ProbeType.markdown:
            return (
                <LetterText className={cn("size-4", className)} {...props} />
            );
        case ProbeType.boolean:
            return (
                <CircleCheckBig
                    className={cn("size-4", className)}
                    {...props}
                />
            );
        case ProbeType.list:
        case ProbeType.finding_list:
            return <List className={cn("size-4", className)} {...props} />;
        default:
            return type satisfies never;
    }
};
