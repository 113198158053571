import { useContext } from "react";
import { useStore } from "zustand";

import { ContextContext } from "@/context/context-context";
import { ContextStore } from "@/stores/context";

export const useContextContext = <T>(
    selector: (state: ReturnType<ContextStore["getState"]>) => T,
): T => {
    const store = useContext(ContextContext);
    if (!store) {
        throw new Error("Missing ContextContextProvider in the component tree");
    }
    return useStore(store, selector);
};
