import { useContext } from "react";
import { toast } from "sonner";

import { UserInfo } from "@/api/rest";
import { User } from "@/api/types";
import { ErrorAlert } from "@/components/error-alert";
import { UserProfileForm } from "@/components/forms/user-profile-form";
import { AppContext } from "@/context/app-context";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";

export const ProfileSettings = () => {
    usePageTitle(["Profile", "Settings"]);
    const api = useApi();
    const { user, mutateUser } = useContext(AppContext);

    const updateUserAction = useAsyncState(
        async (data: Partial<User>) => {
            // optimistic update
            mutateUser({ ...user, ...data } as User, false);
            // mutate and update
            mutateUser(api.update_user_info(data as UserInfo), {
                rollbackOnError: true,
            });
        },
        { onSuccess: () => toast.success("Profile successfully updated") },
    );

    return (
        <div className="col-span-2 space-y-8">
            <h1 className="text-headline text-2xl font-medium">Profile</h1>
            {updateUserAction.error && (
                <ErrorAlert error={updateUserAction.error} />
            )}
            <UserProfileForm user={user!} action={updateUserAction} />
        </div>
    );
};
