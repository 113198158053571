import { Root } from "@radix-ui/react-avatar";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentPropsWithoutRef, memo } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

const variants = cva("bg-transparent", {
    variants: {
        size: {
            xl: "size-9 rounded p-1.5",
            default: "size-4 rounded-none",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

interface Props
    extends ComponentPropsWithoutRef<typeof Root>,
        VariantProps<typeof variants> {
    ticker: string;
}

export const CompanyLogo = memo(
    ({ ticker, size, className, ...props }: Props) => (
        <Avatar className={cn(variants({ size }), className)} {...props}>
            <AvatarImage
                src={`https://financialmodelingprep.com/image-stock/${ticker}.png`}
            />
            <AvatarFallback>{ticker.substring(0, 1)}</AvatarFallback>
        </Avatar>
    ),
);
