import { SetupIntent } from "@stripe/stripe-js";
import { Check, Loader2, MoreVertical, Plus, Trash2 } from "lucide-react";
import { Suspense, createContext, useCallback, useContext } from "react";
import useSWRInfinite from "swr/infinite";

import { PaymentMethod as TPaymentMethod } from "@/api/types";
import {
    PaymentMethod,
    PaymentMethodSkeleton,
} from "@/components/billing/payment-method";
import { ErrorAlert } from "@/components/error-alert";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { BillingSettingsContext } from "@/container/settings/billing";
import {
    AddPaymentMethod,
    AddPaymentMethodSkeleton,
} from "@/container/settings/billing/add-payment-method";
import { useApi } from "@/hooks/use-api";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";
import { first, last, uniqueBy } from "@/utils/collection";
import { getCursorKeyFn } from "@/utils/pagination";

type TContext = {
    setDefault: UseAsyncState<[string, string], unknown>;
    deletePayment: UseAsyncState<[string], unknown>;
};

const Context = createContext<TContext>({} as TContext);

const DefaultBadge = () => (
    <Badge
        variant="outline"
        className="border-slate-400 text-slate-500 dark:border-slate-600"
    >
        DEFAULT
    </Badge>
);

const ActionMenu = (props: { id: string; type: string }) => {
    const { setDefault, deletePayment } = useContext(Context);
    const [isOpen, { open, close, set }] = useBoolean();
    return (
        <Popover open={isOpen} onOpenChange={set}>
            <PopoverTrigger asChild>
                <Button size="icon" variant="ghost" onClick={open}>
                    <MoreVertical className="size-4" />
                    <span className="sr-only">Actions</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                side="bottom"
                align="end"
                className="flex w-44 flex-col p-1"
            >
                <Button
                    variant="ghost"
                    className="justify-start gap-4"
                    onClick={() => {
                        close();
                        setDefault.submit(props.id, props.type);
                    }}
                >
                    <Check className="size-4" />
                    Set as Default
                </Button>
                <Button
                    variant="ghost"
                    className="justify-start gap-4 text-red-500 hover:bg-red-50 hover:text-red-500"
                    onClick={() => {
                        close();
                        deletePayment.submit(props.id);
                    }}
                >
                    <Trash2 className="size-4" />
                    Remove Method
                </Button>
            </PopoverContent>
        </Popover>
    );
};

const Loader = ({ count = 3 }: { count?: number }) => (
    <div className="divide-y px-2">
        {Array(count)
            .fill(0)
            .map((_, i) => (
                <PaymentMethodSkeleton key={i} />
            ))}
    </div>
);

const NullState = () => (
    <p className="p-2 text-sm italic text-gray-500">No payment methods added</p>
);

const PAGE_SIZE = 10;

export const PaymentMethods = () => {
    const api = useApi();
    const [edit, { open, close }] = useBoolean();
    const { defaultPaymentMethod, mutateDefaultPaymentMethod } = useContext(
        BillingSettingsContext,
    );
    const { data, error, mutate, isLoading, isValidating, setSize } =
        useSWRInfinite(
            getCursorKeyFn(PAGE_SIZE, "payment_methods"),
            async ({ pagination }) =>
                await api.fetch_payment_methods(pagination),
        );

    const asyncMutate = useAsyncState(async () => {
        mutateDefaultPaymentMethod();
        mutate();
    });

    const setDefault = useAsyncState(
        async (id: string, type: string) =>
            await api.set_payment_default(id, type),
        { onSuccess: asyncMutate.submit },
    );

    const deletePayment = useAsyncState(
        async (id: string) => await api.delete_payment_method(id),
        { onSuccess: asyncMutate.submit },
    );

    // set as default is data is loaded and no payment methods exist
    const shouldSetAsDefault = first(data ?? [])?.items.length === 0;
    const onAddSuccess = useCallback(
        (si: SetupIntent) => {
            close();
            if (shouldSetAsDefault) {
                const id =
                    si.payment_method != null &&
                    typeof si.payment_method === "object"
                        ? si.payment_method.id
                        : si.payment_method;
                const type = first(si.payment_method_types);
                if (id != null && type != null) {
                    setDefault.submit(id, type);
                }
            } else {
                asyncMutate.submit();
            }
        },
        [close, asyncMutate, setDefault, shouldSetAsDefault],
    );

    if (edit)
        return (
            <Suspense fallback={<AddPaymentMethodSkeleton onCancel={close} />}>
                <AddPaymentMethod onSuccess={onAddSuccess} onCancel={close} />
            </Suspense>
        );

    const methods = uniqueBy(
        [
            ...((defaultPaymentMethod
                ? [defaultPaymentMethod]
                : []) as TPaymentMethod[]),
            ...(data ?? [])
                .flatMap((p) => p.items)
                .sort((a, b) =>
                    a.default === b.default ? 0 : a.default ? -1 : 1,
                ),
        ],
        (i) => i.details.id,
    );

    const isSubmitting =
        setDefault.isSubmitting ||
        deletePayment.isSubmitting ||
        asyncMutate.isSubmitting;

    const hasNextPage = data && last(data)?.page_info.has_next_page;
    const showNullstate = !isLoading && methods.length === 0;
    return (
        <Context.Provider value={{ setDefault, deletePayment }}>
            <div className="relative rounded border">
                <InfiniteScroll
                    hasNextPage={hasNextPage}
                    onNextPage={() => setSize((s) => s + 1)}
                    isLoading={isLoading || (hasNextPage && isValidating)}
                    className="divide-y px-2"
                    loader={<Loader count={isLoading ? 1 : 3} />}
                >
                    {methods.map((method) => (
                        <PaymentMethod key={method.details.id} method={method}>
                            {method.default ? (
                                <DefaultBadge />
                            ) : (
                                <ActionMenu
                                    id={method.details.id}
                                    type={method.payment_type}
                                />
                            )}
                        </PaymentMethod>
                    ))}
                    {showNullstate && <NullState />}
                    {error && <ErrorAlert error={error} />}
                </InfiniteScroll>
                <div className="border-t bg-accent px-2">
                    <Button onClick={open} variant="link" className="p-2">
                        <Plus className="mr-2 size-4" />
                        Add Payment Method
                    </Button>
                </div>
                <div
                    className={cn(
                        "pointer-events-none absolute inset-0 items-center justify-center bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60",
                        isSubmitting ? "flex" : "hidden",
                    )}
                >
                    <Loader2 className="size-6 animate-spin" />
                </div>
            </div>
        </Context.Provider>
    );
};
