import { ArrowUpRight, FileText, Globe } from "lucide-react";
import { HTMLAttributes, memo, SyntheticEvent } from "react";

import { DocumentType, DocumentInfo as TDocumentInfo } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { CompanyLogo } from "@/components/company-logo";
import { DocumentLink } from "@/components/document/document-link";
import { useAsyncState } from "@/hooks/use-async-state";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { urlHostName } from "@/utils/string-helpers";
import { formatDate } from "@/utils/time";

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: TDocumentInfo;
}

const RefreshDocumentBtn = ({ info }: Props) => {
    const [devMode] = useDevMode();
    const invalidateDocument = useGridView((s) => s.invalidateDocument);
    const action = useAsyncState(async (e: SyntheticEvent) => {
        e.stopPropagation();
        return await invalidateDocument(info.id);
    });
    if (!devMode) return null;
    return (
        <div>
            <AsyncButton
                size="xs"
                variant="secondary"
                type="button"
                action={action}
            >
                Refresh Row
            </AsyncButton>
        </div>
    );
};

const FilingSourceButton = ({ url }: { url: string }) => {
    const [devMode] = useDevMode();
    if (!devMode) return null;
    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1 text-xs text-muted-foreground hover:text-foreground"
        >
            <span className="truncate">Filing Source</span>
            <ArrowUpRight className="size-3" />
        </a>
    );
};

export const DocumentInfo = memo(
    ({ info, className, ...props }: Props) => {
        switch (info.doc_type) {
            case DocumentType.website:
                return (
                    <div
                        className={cn("flex items-center gap-2.5", className)}
                        {...props}
                    >
                        <div className="flex size-9 items-center justify-center rounded bg-muted p-1.5">
                            <Globe className="size-4 text-muted-foreground" />
                        </div>
                        <div>
                            <DocumentLink
                                documentID={info.id}
                                className="flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                                role="button"
                            >
                                <span
                                    className={cn(
                                        info.external_ref
                                            ? "line-clamp-1"
                                            : "line-clamp-2",
                                    )}
                                >
                                    {info.title}
                                </span>
                                <ArrowUpRight className="size-4 opacity-0 transition-all group-hover/row:opacity-100" />
                            </DocumentLink>
                            {info.external_ref && (
                                <p className="text-xs text-muted-foreground">
                                    {urlHostName(info.external_ref)}
                                </p>
                            )}
                            <RefreshDocumentBtn info={info} />
                        </div>
                    </div>
                );
            case DocumentType.pdf:
                return (
                    <div
                        className={cn("flex items-center gap-2.5", className)}
                        {...props}
                    >
                        <div className="flex size-9 items-center justify-center rounded bg-muted p-1.5">
                            <FileText className="size-4 text-muted-foreground" />
                        </div>
                        <DocumentLink
                            documentID={info.id}
                            className="flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                            role="button"
                        >
                            <span className="line-clamp-2">{info.title}</span>
                            <ArrowUpRight className="size-4 opacity-0 transition-all group-hover/row:opacity-100" />
                        </DocumentLink>
                        <RefreshDocumentBtn info={info} />
                    </div>
                );
            case DocumentType.sec_filing:
                return (
                    <div
                        className={cn("flex items-center gap-2.5", className)}
                        {...props}
                    >
                        <CompanyLogo
                            ticker={info.ticker}
                            size="xl"
                            className="bg-muted"
                        />
                        <div className="grow space-y-1">
                            <DocumentLink
                                documentID={info.id}
                                className="line-clamp-1 flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                                role="button"
                            >
                                <span>{info.form_type}</span>
                                <ArrowUpRight className="size-4 opacity-0 transition-all group-hover/row:opacity-100" />
                            </DocumentLink>
                            <p className="flex justify-between text-xs text-muted-foreground">
                                <span>{info.ticker}</span>
                                <span>{formatDate(info.filed_at)}</span>
                            </p>
                            <RefreshDocumentBtn info={info} />
                            <FilingSourceButton url={info.url} />
                        </div>
                    </div>
                );
            case DocumentType.earnings_transcript:
                return (
                    <div
                        className={cn("flex items-center gap-2.5", className)}
                        {...props}
                    >
                        <CompanyLogo
                            ticker={info.ticker}
                            size="xl"
                            className="bg-muted"
                        />
                        <div className="grow">
                            <DocumentLink
                                documentID={info.id}
                                className="line-clamp-1 flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                                role="button"
                            >
                                <span>
                                    {info.year} Q{info.quarter} Earnings Call
                                </span>
                                <ArrowUpRight className="size-4 opacity-0 transition-all group-hover/row:opacity-100" />
                            </DocumentLink>
                            <p className="flex justify-between text-xs text-muted-foreground">
                                <span>{info.ticker}</span>
                                <span>{formatDate(info.filed_at)}</span>
                            </p>
                            <RefreshDocumentBtn info={info} />
                        </div>
                    </div>
                );
            default:
                return info satisfies never;
        }
    },
    (prev, next) =>
        prev.className === next.className || prev.info === next.info,
);
