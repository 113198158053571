import { AlertCircle, Loader2 } from "lucide-react";
import { HTMLAttributes, useCallback } from "react";

import {
    ActionConfig,
    ActionLaunchStatus,
    ContextStatus,
    DiscoverPanelStatus,
} from "@/api/types";
import { ActionIcon } from "@/components/action-message/action-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { MAX_WORD_COUNT, MIN_WORD_COUNT } from "@/conf/context-menu";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useContextContext } from "@/hooks/use-context-context";
import { useNetwork } from "@/hooks/use-network";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { getActionsForUser, isContextMenuAction } from "@/utils/actions";
import { emptyFunction } from "@/utils/empty-function";
import { getWordCount } from "@/utils/string-helpers";

const MenuRoot = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "flex flex-col rounded-xl border border-muted-foreground bg-foreground px-4 py-2 shadow-md",
            className,
        )}
        {...props}
    />
);

type TSelection = {
    text: string;
    actionContext: string[];
};

type Props<T> = {
    selection: T;
    onAction?: () => void;
};

const ContextMenuButton = ({
    config,
    selection: { text: selected_text, actionContext },
    onAction = emptyFunction,
}: {
    config: ActionConfig;
} & Props<TSelection>) => {
    const sendAction = useActionsContext((s) => s.send);
    const clickHandler = useCallback(() => {
        sendAction(config.type, {
            selected_text,
            context: actionContext.length > 0 ? actionContext : undefined,
        });
        onAction();
    }, [sendAction, selected_text, onAction]);

    return (
        <Button
            className="justify-start gap-3 px-2 text-background transition-colors hover:bg-neutral-700 hover:text-background dark:hover:bg-neutral-200"
            variant="ghost"
            onClick={clickHandler}
        >
            <ActionIcon type={config.type} />
            <span className="grow text-left">{config.label}</span>
            {config.launchStatus === ActionLaunchStatus.BETA && (
                <Badge variant="beta" className="text-green-500">
                    BETA
                </Badge>
            )}
            {config.launchStatus === ActionLaunchStatus.SUPERADMIN && (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            )}
        </Button>
    );
};

const Menu = (props: Props<TSelection>) => {
    const user = useUser();
    const status = useActionsContext((s) => s.status);
    const word_count = getWordCount(props.selection.text);

    if (word_count < MIN_WORD_COUNT || word_count > MAX_WORD_COUNT) {
        return (
            <MenuRoot className="flex-row items-center gap-3 text-yellow-500">
                <AlertCircle className="size-4 shrink-0" />
                <span className="text-sm">
                    Please select between {MIN_WORD_COUNT} and {MAX_WORD_COUNT}{" "}
                    words
                </span>
            </MenuRoot>
        );
    }

    if (status !== DiscoverPanelStatus.IDLE) {
        return (
            <MenuRoot className="flex-row items-center gap-3 text-background">
                <Loader2 className="size-4 animate-spin" />
                <span className="text-sm">Answering...</span>
            </MenuRoot>
        );
    }

    const actionConfigs = getActionsForUser(user);

    return (
        <MenuRoot className="p-1">
            {actionConfigs.filter(isContextMenuAction).map((config) => (
                <ContextMenuButton
                    key={config.type}
                    config={config}
                    {...props}
                />
            ))}
        </MenuRoot>
    );
};

export const ContextMenu = ({
    selection,
    ...props
}: Props<TSelection | null>) => {
    const isOnline = useNetwork();
    const contextStatus = useContextContext((s) => s.status);
    if (selection === null) return null;
    if (!isOnline) {
        return (
            <MenuRoot className="flex-row items-center gap-3 text-red-500">
                <AlertCircle className="size-4 shrink-0" />
                <span className="text-sm">Not available when offline</span>
            </MenuRoot>
        );
    }

    switch (contextStatus) {
        case ContextStatus.UNINITIALIZED:
        case ContextStatus.LOADING:
            return (
                <MenuRoot className="flex-row items-center gap-3 text-background">
                    <Loader2 className="size-4 shrink-0 animate-spin" />
                    <span className="text-sm">Loading...</span>
                </MenuRoot>
            );
        case ContextStatus.FAILED:
            return (
                <MenuRoot className="flex-row items-center gap-3 text-red-500">
                    <AlertCircle className="size-4 shrink-0" />
                    <span className="text-sm">
                        Failed loading, try refreshing the page
                    </span>
                </MenuRoot>
            );
        case ContextStatus.LOADED:
        case ContextStatus.UPDATING:
            return <Menu selection={selection} {...props} />;
        default:
            return contextStatus satisfies never;
    }
};
