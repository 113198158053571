import { useCallback, useLayoutEffect, useState } from "react";

export type SelectionDetails = {
    text: string;
    range: Range;
    anchorNode: Node;
    selectedParagraph?: boolean;
};

const selectableNode = (node: Node | null | undefined): boolean => {
    console.log(
        "SELECTION",
        node?.parentElement?.closest("[data-bw-actions='true']"),
    );
    return node?.parentElement?.closest("[data-bw-actions='true']") != null;
};

export const useSelection = () => {
    const [selection, setSelection] = useState<SelectionDetails | null>(null);

    const onSelectionChange = useCallback(() => {
        // clear selection if selection is removed
        const s = document.getSelection();
        if (!s || s.isCollapsed) {
            setSelection(null);
        }
    }, [setSelection]);

    const updateSelection = useCallback(
        (e: MouseEvent | TouchEvent) => {
            const selection = document.getSelection();
            const anchor = selection?.anchorNode;
            if (
                selection != null &&
                !selection.isCollapsed &&
                anchor &&
                selectableNode(anchor)
            ) {
                const range = selection.getRangeAt(0);
                setSelection((s) => ({
                    text: selection.toString().trim(),
                    range,
                    selectedParagraph: s?.selectedParagraph || e.detail === 3,
                    anchorNode: anchor,
                }));
            }
        },
        [setSelection],
    );

    useLayoutEffect(() => {
        document.addEventListener("mouseup", updateSelection);
        document.addEventListener("touchend", updateSelection);
        document.addEventListener("selectionchange", onSelectionChange);
        return () => {
            document.removeEventListener("mouseup", updateSelection);
            document.addEventListener("touchend", updateSelection);
            document.removeEventListener("selectionchange", onSelectionChange);
        };
    }, [onSelectionChange]);

    return selection;
};
