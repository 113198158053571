import { Loader2 } from "lucide-react";
import { PropsWithChildren, Suspense, useContext } from "react";
import { Outlet } from "react-router-dom";

import { User } from "@/api/types";
import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Button } from "@/components/ui/button";
import { AppContext } from "@/context/app-context";

const getUserName = (user: User): string => {
    if (user.first_name || user.last_name) {
        return [user.first_name, user.last_name].join(" ");
    }
    return user.username;
};

const Loader = () => (
    <div className="flex justify-center p-20">
        <Loader2 className="animate-spin" />
    </div>
);

export const Payment = (props: PropsWithChildren) => {
    const { user, logout } = useContext(AppContext);
    return (
        <>
            <header className="fixed left-0 right-0 z-50 flex h-14 w-full items-center justify-between border-b border-border bg-background/95 px-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <BrightwaveLogo />
                {user && (
                    <div className="flex justify-end divide-x divide-border text-sm [&_*]:px-2">
                        <p>{getUserName(user)}</p>
                        <Button
                            variant="link"
                            size="sm"
                            className="px-0"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </div>
                )}
            </header>
            <main className="mx-auto max-w-screen-sm space-y-10 px-8 py-28">
                <Suspense fallback={<Loader />}>
                    {props.children ?? <Outlet />}
                </Suspense>
            </main>
        </>
    );
};
