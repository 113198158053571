/* eslint react-compiler/react-compiler: 0 */
// Disabled eslint because once react compiler and react 19 are released
// refs are props and forwardRefs depreacted

import { zodResolver } from "@hookform/resolvers/zod";
import { AlertTriangle, SendHorizonal } from "lucide-react";
import {
    ClipboardEvent,
    forwardRef,
    HTMLAttributes,
    KeyboardEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useBoolean } from "@/hooks/use-boolean";
import { useForwardRef } from "@/hooks/use-forward-ref";
import { cn } from "@/lib/utils";
import { getMaxHeight } from "@/utils/css";
import { getWordCount, isEmptyOrNull } from "@/utils/string-helpers";

const WARNING_WORD_COUNT = 120;

const formSchema = z.object({
    message: z.string().trim().min(1),
});

interface Props extends HTMLAttributes<HTMLDivElement> {
    placeholder?: string;
    disabled?: boolean;
    onMessage: (message: string) => void;
    autoFocus?: boolean;
    leftContent?: ReactNode;
}

export const MessageForm = forwardRef<HTMLTextAreaElement, Props>(
    (
        {
            disabled,
            placeholder,
            onMessage,
            autoFocus = false,
            leftContent,
            className,
            children,
            ...props
        },
        forwardedRef,
    ) => {
        const [showWarning, setShowWarning] = useState(false);
        const [isFocused, focusActions] = useBoolean();
        const ref = useForwardRef<HTMLTextAreaElement>(forwardedRef);
        const shouldFocus = useRef<boolean>(false);

        const form = useForm<z.infer<typeof formSchema>>({
            resolver: zodResolver(formSchema),
            defaultValues: { message: "" },
        });

        const submitForm = form.handleSubmit(
            (values: z.infer<typeof formSchema>) => {
                shouldFocus.current = true;
                onMessage(values.message);
                form.reset();
            },
        );

        useEffect(() => {
            if (!disabled && shouldFocus.current === true) {
                shouldFocus.current = false;
                ref.current?.focus();
            }
        }, [disabled]);

        const updateTextareaHeight = useCallback(() => {
            const el = ref.current;
            if (!el) return;
            el.style.height = "auto";
            if (el.scrollHeight) {
                el.style.height = el.scrollHeight + "px";
                el.style.overflowY =
                    el.scrollHeight > getMaxHeight(el) ? "scroll" : "hidden";
            }
        }, [ref.current]);

        useEffect(() => {
            const el = ref.current;
            if (!el) return;

            updateTextareaHeight();

            el.addEventListener("input", updateTextareaHeight);
            const resizeObserver = new ResizeObserver(updateTextareaHeight);
            resizeObserver.observe(el);

            return () => {
                el.removeEventListener("input", updateTextareaHeight);
                resizeObserver.disconnect();
            };
        });

        const message = form.watch("message");
        useEffect(() => {
            updateTextareaHeight();
            setShowWarning(getWordCount(message) > WARNING_WORD_COUNT);
        }, [message]);

        const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData("Text").trim();
            const input = e.currentTarget;
            const start = input.selectionStart;
            const end = input.selectionEnd;
            const currentValue = input.value;
            form.setValue(
                "message",
                currentValue.slice(0, start) +
                    pastedText +
                    currentValue.slice(end),
            );
        };

        const handleKeyDown = useCallback(
            (e: KeyboardEvent<HTMLTextAreaElement>) => {
                if (!e.shiftKey && e.key === "Enter") {
                    e.preventDefault();
                    submitForm();
                }
            },
            [submitForm],
        );

        const is_iPad = /iPad/.test(navigator.userAgent);

        return (
            <>
                <Form {...form}>
                    <form onSubmit={submitForm}>
                        <FormField
                            control={form.control}
                            name="message"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <div
                                            {...props}
                                            className={cn(
                                                "relative m-4 rounded-[1.75rem] border border-input bg-background px-4 py-1.5 pr-14 ring-4 ring-page-background ring-offset-0 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
                                                showWarning &&
                                                    "ring-2 ring-amber-500 ring-offset-2 focus-within:ring-amber-500",
                                                leftContent && "pl-14",
                                                className,
                                            )}
                                        >
                                            {showWarning && (
                                                <p className="flex items-center gap-1.5 pb-2 text-sm font-medium text-amber-500">
                                                    <AlertTriangle className="size-4 -translate-y-px" />
                                                    Chat works best when you
                                                    break tasks down into
                                                    smaller pieces.
                                                </p>
                                            )}
                                            {leftContent && (
                                                <div className="absolute bottom-1.5 left-1.5">
                                                    {leftContent}
                                                </div>
                                            )}
                                            <Textarea
                                                autoFocus={autoFocus}
                                                placeholder={
                                                    placeholder ??
                                                    "Deepen your understanding of anything covered by this report..."
                                                }
                                                className="max-h-36 min-h-10 resize-none overflow-hidden rounded-none border-none pl-0 pr-1 text-base focus-visible:ring-0 focus-visible:ring-offset-0"
                                                onFocus={focusActions.open}
                                                rows={1}
                                                {...field}
                                                onKeyDown={handleKeyDown}
                                                onBlur={() => {
                                                    field.onBlur();
                                                    focusActions.close();
                                                }}
                                                onPaste={handlePaste}
                                                ref={(e) => {
                                                    ref.current = e;
                                                    field.ref(e);
                                                }}
                                            />
                                            <Button
                                                type="submit"
                                                size="icon"
                                                variant={
                                                    disabled ||
                                                    isEmptyOrNull(field.value)
                                                        ? "secondary"
                                                        : "primary"
                                                }
                                                disabled={
                                                    disabled ||
                                                    isEmptyOrNull(field.value)
                                                }
                                                className="absolute bottom-1.5 right-1.5 rounded-full"
                                            >
                                                <SendHorizonal className="size-5" />
                                                <span className="sr-only">
                                                    Send
                                                </span>
                                            </Button>
                                        </div>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </form>
                </Form>
                {is_iPad && isFocused && <div className="shrink-0 basis-16" />}
            </>
        );
    },
);
