import { Check, Copy, Loader2, RefreshCw, ThumbsDown } from "lucide-react";
import { useCallback } from "react";
import { toast } from "sonner";

import { FeedbackObjectType } from "@/api/rest";
import { APActionStatus, APActionType, APActionBase } from "@/api/types";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useClipboard } from "@/hooks/use-clipboard";
import { cn } from "@/lib/utils";
import { getResponse } from "@/utils/actions";
import { emptyFunction } from "@/utils/empty-function";

// naming is hard
export const ActionActions = ({ action }: { action: APActionBase }) => {
    const api = useApi();
    const messageID = useActionsContext((s) => s.messageID);
    const sendAction = useActionsContext((s) => s.send);
    const clipboard = useClipboard({ timeout: 1000 });
    const response = getResponse(action);

    const copy = useCallback(() => {
        if (response != undefined) {
            clipboard.copy(response.content);
        }
    }, [response, clipboard.copy]);

    const regenerate = useCallback(() => {
        switch (action.type) {
            case APActionType.chat_message:
            case APActionType.search:
                return sendAction(action.type, { message: action.content });
            case APActionType.implications:
            case APActionType.market_commentary:
            case APActionType.show_evidence:
            case APActionType.tell_me_more:
            case APActionType.thesis_generation:
            case APActionType.follow_up_questions:
                return sendAction(action.type, {
                    selected_text: action.content,
                });
            default:
                return action.type satisfies never;
        }
    }, [sendAction, action.content, action.type]);

    const {
        submit,
        isSubmitting,
        data: submitted,
    } = useAsyncState(
        async () => {
            await api.send_feedback(messageID, {
                object_id: action.id,
                object_type: FeedbackObjectType.ACTION,
                positive: false,
            });
            return true;
        },
        {
            onError: () => toast.error("Failed to save feedback"),
            onSuccess: () => toast.success("Thank you for your feedback"),
        },
    );

    if (action.status !== APActionStatus.complete || response == null)
        return null;

    return (
        <div>
            <ButtonWithTooltip
                variant="ghost"
                size="icon-sm"
                className="text-muted-foreground"
                onClick={copy}
                tooltip={clipboard.copied ? "Copied" : "Copy"}
            >
                {clipboard.copied ? (
                    <Check className="size-4" />
                ) : (
                    <Copy className="size-4" />
                )}
            </ButtonWithTooltip>
            <ButtonWithTooltip
                variant="ghost"
                size="icon-sm"
                className="text-muted-foreground"
                onClick={regenerate}
                tooltip={"Regenerate"}
            >
                <RefreshCw className="size-4" />
            </ButtonWithTooltip>
            <ButtonWithTooltip
                variant="ghost"
                size="icon-sm"
                className="text-muted-foreground"
                onClick={submitted ? emptyFunction : submit}
                tooltip={"Bad response"}
            >
                {isSubmitting ? (
                    <Loader2 className="size-4 animate-spin" />
                ) : (
                    <ThumbsDown
                        className={cn(
                            "size-4",
                            submitted && "text-destructive",
                        )}
                    />
                )}
            </ButtonWithTooltip>
        </div>
    );
};
