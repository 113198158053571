import { useCallback } from "react";
import { useRouteError } from "react-router-dom";

import { Debugger } from "@/components/debug/debugger";
import { ErrorAlert } from "@/components/error-alert";
import { Headline } from "@/components/headline";
import { Button } from "@/components/ui/button";
import { AuthLayout } from "@/layouts/auth-layout";

export const ErrorPage = (props: { error?: Error }) => {
    const recover = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/");
    }, []);
    return (
        <AuthLayout>
            <div className="space-y-10">
                <Headline level={2}>We're sorry, something went wrong</Headline>
                <p>
                    Try to refresh, clear your cache, and contact{" "}
                    <a
                        href="mailto:support@brightwave.io"
                        className="underline-offset-4 hover:underline"
                    >
                        support@brightwave.io
                    </a>{" "}
                    if the error persists.
                </p>
                <Button variant="destructive" size="lg" onClick={recover}>
                    Clear Cache and Refresh
                </Button>
                <Debugger showOnDevEnv>
                    <ErrorAlert error={props.error} />
                </Debugger>
            </div>
        </AuthLayout>
    );
};

export const ErrorRoute = () => {
    const error = useRouteError();
    return <ErrorPage error={error instanceof Error ? error : undefined} />;
};
