import { BookOpenCheck, X } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

import { ColumnType } from "@/api/types";
import { ProbesLibrary } from "@/components/document-table/columns/probes-library";
import { SystemColumns } from "@/components/document-table/columns/system-columns";
import { Button } from "@/components/ui/button";
import {
    SheetClose,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const ExistingColumns = () => {
    const [tab, setTab] = useState<ColumnType>(ColumnType.system);

    const content = useMemo(() => {
        switch (tab) {
            case ColumnType.system:
                return <SystemColumns />;
            case ColumnType.user_defined:
                return <ProbesLibrary />;
            default:
                return tab satisfies never;
        }
    }, [tab]);

    const handleTabChange = useCallback(
        (val: string) => setTab(val as ColumnType),
        [setTab],
    );

    return (
        <>
            <SheetHeader className="mb-4 min-h-12 flex-row items-center justify-between space-y-0 border-b px-2">
                <SheetTitle className="px-2">Add Column</SheetTitle>
                <SheetDescription className="sr-only">
                    Add a Brightwave column or create and add a new custom
                    column
                </SheetDescription>
                <SheetClose asChild>
                    <Button variant="ghost" size="icon-sm">
                        <X className="size-4" />
                    </Button>
                </SheetClose>
            </SheetHeader>
            <Tabs value={tab} onValueChange={handleTabChange} className="px-4">
                <TabsList className="flex">
                    <TabsTrigger value={ColumnType.system} className="flex-1">
                        Presets
                    </TabsTrigger>
                    <TabsTrigger
                        value={ColumnType.user_defined}
                        className="flex-1 gap-2"
                    >
                        <BookOpenCheck className="size-4" />
                        Your Library
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            {content}
        </>
    );
};
