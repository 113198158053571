import { Skeleton } from "@/components/ui/skeleton";
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableHeader,
} from "@/components/ui/table";
import { AppLayout } from "@/layouts/app-layout";

export const GridViewSkeleton = ({ rowCount = 20 }: { rowCount?: number }) => (
    <AppLayout
        header={<Skeleton className="h-10 flex-1" />}
        className="pointer-events-none flex h-screen grow flex-col bg-page-background"
    >
        <div className="flex min-h-14 items-center justify-between gap-8 border-b border-foreground/5 bg-foreground px-4 py-2 dark:bg-neutral-800">
            <Skeleton className="h-10 w-32 bg-muted-foreground" />
            <div className="flex grow items-center gap-8">
                <Skeleton className="h-4 w-20 bg-muted-foreground" />
                <Skeleton className="h-4 w-56 bg-muted-foreground" />
            </div>
            <Skeleton className="h-10 w-32 bg-muted-foreground" />
        </div>
        <Table className="h-px bg-background">
            <TableHeader>
                <TableRow>
                    <TableHead className="min-w-96 border-r">
                        <div className="flex items-center gap-4">
                            <Skeleton className="size-4 rounded-sm" />
                            <Skeleton className="h-4 w-full" />
                        </div>
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {Array.from(Array(rowCount)).map((_, i) => (
                    <TableRow key={i}>
                        <TableCell className="border-r">
                            <div className="flex h-full items-center gap-4">
                                <Skeleton className="size-4 rounded-sm" />
                                <Skeleton className="size-12" />
                                <div className="grow space-y-3">
                                    <Skeleton className="h-4 w-full" />
                                    <Skeleton className="h-3 w-1/2" />
                                </div>
                            </div>
                        </TableCell>
                        <TableCell className="space-y-3">
                            <Skeleton className="h-3 w-full" />
                            <Skeleton className="h-3 w-full" />
                            <Skeleton className="h-3 w-1/2" />
                        </TableCell>
                        <TableCell className="space-y-3">
                            <Skeleton className="h-3 w-1/3" />
                        </TableCell>
                        <TableCell>
                            <div className="flex flex-wrap gap-2">
                                <Skeleton className="h-5 w-40" />
                                <Skeleton className="h-5 w-52" />
                                <Skeleton className="h-5 w-52" />
                                <Skeleton className="h-5 w-44" />
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </AppLayout>
);
