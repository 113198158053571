import { Plus } from "lucide-react";
import { useCallback, useMemo } from "react";

import { CreateColumn } from "@/components/document-table/columns/create-column";
import { EditColumn } from "@/components/document-table/columns/edit-column";
import { ExistingColumns } from "@/components/document-table/columns/existing-columns";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { PageType, useColumnSheet } from "@/hooks/use-column-sheet";

type PointerDownOutsideEvent = CustomEvent<{
    originalEvent: PointerEvent;
}>;

export const ColumnSheet = () => {
    const isOpen = useColumnSheet((s) => s.isOpen);
    const setOpen = useColumnSheet((s) => s.setOpen);
    const close = useColumnSheet((s) => s.close);
    const page = useColumnSheet((s) => s.page);
    const setPage = useColumnSheet((s) => s.setPage);

    const handlePointerDownOutside = useCallback(
        (e: PointerDownOutsideEvent) => {
            if (page.type === PageType.newColumn) {
                e.preventDefault();
            }
        },
        [page],
    );

    const content = useMemo(() => {
        switch (page.type) {
            case PageType.newColumn:
                return (
                    <CreateColumn
                        onSuccess={close}
                        onCancel={() => setPage(PageType.systemColumns)}
                    />
                );
            case PageType.systemColumns:
            case PageType.customColumns:
                return (
                    <>
                        <ExistingColumns />
                        <div className="border-t p-2">
                            <Button
                                variant="primary"
                                className="w-full gap-2"
                                onClick={() => setPage(PageType.newColumn)}
                            >
                                <Plus className="size-4" />
                                Create New Column
                            </Button>
                        </div>
                    </>
                );
            case PageType.editColumn:
                return <EditColumn probe={page.probe} onSuccess={close} />;
            default:
                return page satisfies never;
        }
    }, [page]);

    const minWidth = useMemo(() => {
        switch (page.type) {
            case PageType.editColumn:
            case PageType.newColumn:
                return "800px";
            case PageType.systemColumns:
            case PageType.customColumns:
                return "550px";
            default:
                return page satisfies never;
        }
    }, [page]);

    return (
        <Sheet open={isOpen} onOpenChange={setOpen}>
            <SheetContent
                side="right"
                className="focus:ring-none flex flex-col border-none bg-transparent p-0 shadow-none transition-[min-width]"
                style={{ minWidth }}
                withCloseButton={false}
                onPointerDownOutside={handlePointerDownOutside}
                blurred
            >
                <div className="m-2 flex grow flex-col overflow-hidden rounded-lg border bg-background shadow-lg">
                    {content}
                </div>
            </SheetContent>
        </Sheet>
    );
};
