import { useContext } from "react";
import { useStore } from "zustand";

import { ActionsContext } from "@/context/actions-context";
import { ActionsStore } from "@/stores/actions";

export const useActionsContext = <T>(
    selector: (state: ReturnType<ActionsStore["getState"]>) => T,
): T => {
    const store = useContext(ActionsContext);
    if (!store) {
        throw new Error("Missing ActionsContextProvider in the component tree");
    }
    return useStore(store, selector);
};
