import { StateCreator } from "zustand";

export const onChangeMiddleware =
    <S, T>(
        initializer: StateCreator<S, [], []>,
        selector: (state: S) => T,
        onChange: (val: T) => void,
        eq: (a: T, b: T) => boolean = (a: T, b: T) => a === b,
    ): StateCreator<S, [], []> =>
    (set, get, api) => {
        const patchedSet: typeof set = (...args) => {
            const prev = selector(get());
            set(...args);
            const next = selector(get());
            if (!eq(prev, next)) {
                onChange(next);
            }
        };
        return initializer(patchedSet, get, api);
    };
