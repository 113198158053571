import { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";
import { parseISO } from "date-fns";
import {
    FilePlus2,
    Info,
    Loader2,
    MessageSquareQuote,
    Plus,
    X,
} from "lucide-react";
import {
    PropsWithChildren,
    Suspense,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ImperativePanelHandle } from "react-resizable-panels";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { validate as validateUUID } from "uuid";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

import {
    ContextType,
    DocumentStatus,
    DocumentType,
    UUID,
    AsyncFindingGroup,
    UploadFile,
} from "@/api/types";
import { ActionPanel } from "@/components/action-panel/action-panel";
import { ActionPanelHeader } from "@/components/action-panel/header";
import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { CellState } from "@/components/analyze/cell-state";
import { DocumentSearch } from "@/components/analyze/document-search";
import { DocumentStatusGate } from "@/components/analyze/document-status-gate";
import { Dropzone } from "@/components/analyze/dropzone";
import { OverlayContainer, OverlayContent } from "@/components/analyze/overlay";
import { GridViewSkeleton } from "@/components/analyze/skeleton";
import { UploadToast } from "@/components/analyze/upload-toast";
import { Debugger } from "@/components/debug/debugger";
import { DocumentLinkContext } from "@/components/document/document-link";
import { ColumnHeader } from "@/components/document-table/column-header";
import { ColumnSheet } from "@/components/document-table/columns/sheet";
import { DocumentInfo } from "@/components/document-table/document-info";
import { Filter } from "@/components/document-table/filter";
import { FindingGroupContent } from "@/components/document-table/finding-group-content";
import {
    DocumentWithAsyncFindings,
    PowerTable,
} from "@/components/document-table/power-table";
import { Sorting } from "@/components/document-table/sorting";
import { ReportHeader } from "@/components/report/header";
import { Button } from "@/components/ui/button";
import { StaticColumn } from "@/conf/grid-view";
import { MAX_DOCUMENT_COUNT } from "@/conf/report";
import { ContextMenuContainer } from "@/container/context-menu";
import { DocumentContainer } from "@/container/grid-view-document-container";
import { ActionsContextProvider } from "@/context/actions-context";
import { ContextContextProvider } from "@/context/context-context";
import { FileUploadContextProvider } from "@/context/file-upload-context";
import { GridViewContextProvider } from "@/context/grid-view-context";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useContextMenu } from "@/hooks/use-context-menu";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { useGridView } from "@/hooks/use-grid-view-context";
import { usePageTitle } from "@/hooks/use-page-title";
import { AppLayout } from "@/layouts/app-layout";
import { NotFoundRoute } from "@/routes/not-found";
import { getColumId, getColumnIdFromFindingGroup } from "@/utils/columns";
import { isFileUploaded } from "@/utils/file-upload";
import { maybeGetFindingGroup } from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";
import { getReportTitle } from "@/utils/report";
import { plural } from "@/utils/string-helpers";

type Store = {
    id: UUID | undefined;
    setDocument: (id: UUID) => void;
    clearDocument: () => void;
};
const useAnalyze = create<Store>((set) => ({
    id: undefined,
    setDocument(id) {
        set({ id });
    },
    clearDocument() {
        set({ id: undefined });
    },
}));

const valueInSetFilterFn = (
    row: Row<DocumentWithAsyncFindings>,
    columnId: string,
    filterValue: Set<string> | undefined,
): boolean => {
    return (
        filterValue === undefined ||
        filterValue.size === 0 ||
        filterValue.has(row.getValue(columnId))
    );
};

const columnHelper = createColumnHelper<DocumentWithAsyncFindings>();

const staticColumns = [
    columnHelper.accessor((row) => row.info, {
        id: StaticColumn.document,
        header({ table }) {
            return (
                <div className="flex w-full items-center justify-between gap-2">
                    <span className="grow">
                        {plural(table.getRowCount(), "Document")}
                    </span>
                    <Filter table={table} />
                </div>
            );
        },
        cell({ getValue, row }) {
            return (
                <DocumentInfo
                    info={getValue()}
                    className="relative h-full p-4"
                    onClick={row.getToggleSelectedHandler()}
                />
            );
        },
        enableColumnFilter: false,
        size: 300,
        enableResizing: false,
        meta: {
            removePadding: true,
        },
    }),
    columnHelper.accessor(
        (row) =>
            row.info.content_date ? parseISO(row.info.content_date) : undefined,
        { id: StaticColumn.content_date },
    ),
    columnHelper.accessor(
        (row) => ("ticker" in row.info ? row.info.ticker : ""),
        {
            id: StaticColumn.ticker,
            filterFn: valueInSetFilterFn,
        },
    ),
    columnHelper.accessor("info.doc_type", {
        id: StaticColumn.doc_type,
        filterFn: valueInSetFilterFn,
    }),
    columnHelper.accessor("info.status", {
        id: StaticColumn.document_status,
        header: "Status",
        cell: ({ getValue }) => (
            <DocumentStatusGate status={getValue()}>
                <CellState>Document Ready</CellState>
            </DocumentStatusGate>
        ),
        enableResizing: false,
    }),
];

const AnalyzeRouteImpl = () => {
    const actionPanelRef = useRef<ImperativePanelHandle>(null);
    const documentPanelRef = useRef<ImperativePanelHandle>(null);
    const contextMenu = useContextMenu();

    const report = useGridView((s) => s.report);
    const documents = useGridView((s) => s.documents);
    const addItems = useGridView((s) => s.addItems);
    const deleteItems = useGridView((s) => s.deleteItems);

    const selectedDocumentID = useAnalyze((s) => s.id);
    const selectDocument = useAnalyze((s) => s.setDocument);
    const closeDocument = useAnalyze((s) => s.clearDocument);

    const isActionPanelOpen = useActionsContext((s) => s.isActionPanelOpen);
    const openActionPanel = useActionsContext((s) => s.actionPanelOpen);
    const closeActionPanel = useActionsContext((s) => s.actionPanelClose);

    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    usePageTitle(getReportTitle(report));

    const maxFiles = useFileUploadContext((s) => s.maxFiles);
    const { open: fileDialogOpen } = useFileUploadContext((s) => s.dropzone);
    const handleFileOpen = useCallback(() => {
        if (maxFiles <= 0) {
            return toast.error("File limit reached");
        }
        fileDialogOpen();
    }, [maxFiles, fileDialogOpen]);

    const addedDocumentIDs = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.documents.values())
                        .filter(
                            (d) =>
                                d.doc_type === DocumentType.sec_filing ||
                                d.doc_type === DocumentType.earnings_transcript,
                        )
                        .map((d) => d.unique_id),
                ),
        ),
    );

    const toggleActionPanel = useCallback(
        () => (isActionPanelOpen ? closeActionPanel() : openActionPanel()),
        [isActionPanelOpen, closeActionPanel, openActionPanel],
    );

    const dismissOverlay = useCallback(() => {
        closeActionPanel();
        closeDocument();
    }, [closeActionPanel, closeDocument]);

    useHotkeys("mod+j", toggleActionPanel, { enableOnFormTags: true });
    useHotkeys("esc", dismissOverlay, { enableOnFormTags: true });

    const docList = useGridView(
        useShallow((s) => Array.from(s.documents.values())),
    );
    const mapping = useGridView((s) => s.documents_to_finding_groups);
    const finding_groups = useGridView((s) => s.finding_groups);
    const rows = useMemo(
        () =>
            docList.map(
                (info): DocumentWithAsyncFindings => ({
                    info,
                    finding_groups: new Map(
                        (mapping.get(info.id) ?? [])
                            .map((id) => finding_groups.get(id))
                            .filter(nonNull)
                            .map((fg): [string, AsyncFindingGroup] | null => {
                                const key = getColumnIdFromFindingGroup(fg);
                                return key ? [key, fg] : null;
                            })
                            .filter(nonNull),
                    ),
                }),
            ),
        [docList, mapping, finding_groups],
    );

    const updateReportTitle = useGridView((s) => s.updateReportTitle);
    const columns = useGridView((s) => s.columns);

    const tableCols = useMemo(
        () =>
            [
                ...staticColumns,
                ...columns.map((col) =>
                    columnHelper.accessor(
                        (
                            row,
                        ): [DocumentStatus, AsyncFindingGroup | undefined] => [
                            row.info.status,
                            row.finding_groups.get(getColumId(col)),
                        ],
                        {
                            id: getColumId(col),
                            header: () => <ColumnHeader column={col} />,
                            cell: ({ getValue }) => {
                                const [status, findingGroup] = getValue();
                                return (
                                    <DocumentStatusGate status={status}>
                                        <Debugger>
                                            <CopyToClipboard
                                                value={JSON.stringify(
                                                    maybeGetFindingGroup(
                                                        findingGroup,
                                                    ),
                                                    null,
                                                    2,
                                                )}
                                                asChild
                                            >
                                                <Button
                                                    size="xs"
                                                    variant="secondary"
                                                    className="absolute right-1 top-1"
                                                >
                                                    <Info className="size-4" />
                                                </Button>
                                            </CopyToClipboard>
                                        </Debugger>
                                        {findingGroup !== undefined ? (
                                            <FindingGroupContent
                                                findingGroup={findingGroup}
                                            />
                                        ) : (
                                            <CellState variant="loading">
                                                Analyzing...
                                            </CellState>
                                        )}
                                    </DocumentStatusGate>
                                );
                            },
                            size: 400,
                        },
                    ),
                ),
            ] as ColumnDef<DocumentWithAsyncFindings>[],
        [columns],
    );

    const columnSizing = useGridView(useShallow((s) => s.columnSizing));
    const setColumnSizing = useGridView(useShallow((s) => s.setColumnSizing));
    const columnOrder = useGridView(useShallow((s) => s.columnOrder));
    const setColumnOrder = useGridView(useShallow((s) => s.setColumnOrder));
    const columnVisibility = useGridView(useShallow((s) => s.columnVisibility));
    const sorting = useGridView(useShallow((s) => s.sorting));
    const setSorting = useGridView((s) => s.setSorting);

    const addColumn = useColumnSheet((s) => s.open);

    return (
        <DocumentLinkContext.Provider value={{ onNavigate: selectDocument }}>
            <AppLayout
                header={
                    <div className="flex grow items-center gap-2">
                        <Button
                            variant="outline"
                            className="gap-2 text-muted-foreground"
                            onClick={handleFileOpen}
                        >
                            <FilePlus2 className="size-4" />
                            Add PDFs
                        </Button>
                        <DocumentSearch
                            addedDocumentIDs={addedDocumentIDs}
                            addItems={addItems}
                            placeholder="Search for additional companies and documents..."
                            maxDocumentCount={
                                MAX_DOCUMENT_COUNT - documents.size
                            }
                        />
                    </div>
                }
                className="relative flex h-screen grow flex-col bg-page-background"
            >
                <ReportHeader
                    report={report}
                    onTitleChange={updateReportTitle}
                    left={
                        <Button
                            variant="primary"
                            className="gap-2"
                            onClick={toggleActionPanel}
                        >
                            <MessageSquareQuote className="size-4 translate-y-px" />
                            Chat
                        </Button>
                    }
                    right={
                        <Button
                            variant="primary"
                            className="gap-2"
                            onClick={addColumn}
                        >
                            <Plus className="size-4" />
                            Add Column
                        </Button>
                    }
                />
                <Debugger>
                    <div className="flex gap-4 border-b bg-background px-4 py-2 text-sm">
                        <Sorting
                            sorting={sorting}
                            onSortingChange={setSorting}
                        />
                    </div>
                </Debugger>
                <PowerTable
                    data={rows}
                    columns={tableCols}
                    onDeleteRow={deleteItems}
                    columnPinning={{ left: [StaticColumn.document] }}
                    columnVisibility={{
                        ...columnVisibility,
                        document_status:
                            documents.size > 0 && columns.length === 0,
                    }}
                    initialColumnSizing={columnSizing}
                    onColumnSizingChange={setColumnSizing}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    sorting={sorting}
                    setSorting={setSorting}
                >
                    No documents.
                </PowerTable>
                <OverlayContainer onDismiss={dismissOverlay}>
                    <OverlayContent
                        side="left"
                        panelRef={actionPanelRef}
                        open={isActionPanelOpen}
                    >
                        <ActionPanel
                            header={
                                <ActionPanelHeader onClose={closeActionPanel} />
                            }
                            className="flex w-full flex-col"
                        />
                    </OverlayContent>
                    <OverlayContent
                        side="right"
                        panelRef={documentPanelRef}
                        open={selectedDocumentID != undefined}
                    >
                        <div className="flex min-h-12 items-center justify-between gap-2 border-b bg-background p-1">
                            <p className="px-3 font-headline text-xl font-bold">
                                Summary
                            </p>
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={closeDocument}
                            >
                                <X className="size-4" />
                            </Button>
                        </div>
                        <Suspense
                            fallback={
                                <div className="flex items-center p-20">
                                    <Loader2 className="size-4 animate-spin" />
                                </div>
                            }
                        >
                            <DocumentContainer
                                id={selectedDocumentID}
                                fallback={
                                    <div className="flex grow items-center justify-center">
                                        Document was deleted from this report
                                    </div>
                                }
                            />
                        </Suspense>
                    </OverlayContent>
                </OverlayContainer>
                <Dropzone />
                <UploadToast
                    files={Array.from(files.values())}
                    onDismiss={resetFiles}
                />
                <ContextMenuContainer contextMenu={contextMenu} />
                <ColumnSheet />
            </AppLayout>
        </DocumentLinkContext.Provider>
    );
};

const FileUploadWrapper = (props: PropsWithChildren) => {
    const remainingCount = useGridView(
        (s) => MAX_DOCUMENT_COUNT - s.documents.size,
    );
    const addItems = useGridView((s) => s.addItems);
    const onUploadComplete = useCallback(
        (files: UploadFile[]) =>
            addItems(
                files.filter(isFileUploaded).map((f) => ({
                    type: ContextType.EXISTING_DOCUMENT,
                    document_id: f.document_info.id,
                })),
            ),
        [addItems],
    );
    return (
        <FileUploadContextProvider
            maxFiles={remainingCount}
            onUploadComplete={onUploadComplete}
        >
            {props.children}
        </FileUploadContextProvider>
    );
};

export const AnalyzeRoute = () => {
    const { id } = useParams<{ id: string }>();

    if (id === undefined || !validateUUID(id)) {
        return <NotFoundRoute />;
    }

    return (
        <Suspense fallback={<GridViewSkeleton />}>
            <ContextContextProvider messageID={id}>
                <GridViewContextProvider key={id} messageID={id}>
                    <ActionsContextProvider messageID={id}>
                        <FileUploadWrapper>
                            <AnalyzeRouteImpl />
                        </FileUploadWrapper>
                    </ActionsContextProvider>
                </GridViewContextProvider>
            </ContextContextProvider>
        </Suspense>
    );
};
