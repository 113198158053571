import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import {
    ColumnType,
    FindingGroupLaunchStatus,
    FindingGroupType,
} from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { DISABLED_FINDING_GROUP_TYPES } from "@/conf/grid-view";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useUser } from "@/hooks/use-user";
import { hasBetaAccess } from "@/utils/user";

export const SystemColumns = () => {
    const isBetaUser = hasBetaAccess(useUser());
    const availableCols = useGridView(
        useShallow(
            (s) =>
                new Map(
                    Array.from(s.availableColumns.values())
                        .filter(
                            (c) =>
                                c.is_system &&
                                !DISABLED_FINDING_GROUP_TYPES.has(c.type) &&
                                (isBetaUser ||
                                    c.launch_status ==
                                        FindingGroupLaunchStatus.launched),
                        )
                        .sort((a, b) => a.type.localeCompare(b.type))
                        .map((col) => [col.type, col]),
                ),
        ),
    );
    const addedColTypes = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.columns.values()).map(
                        (col) => col.finding_group_type,
                    ),
                ),
        ),
    );
    const addColumn = useGridView((s) => s.addColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);
    const handleCheckedChange = useAsyncState(
        async (type: FindingGroupType, isChecked: boolean) => {
            if (isChecked) {
                await addColumn({
                    column_type: ColumnType.system,
                    finding_group_type: type,
                });
            } else {
                await deleteColumn({
                    column_type: ColumnType.system,
                    finding_group_type: type,
                });
            }
            return { isChecked, column: availableCols.get(type)! };
        },
        {
            onSuccess: ({ isChecked, column }) =>
                toast.success(
                    `Column "${column.description}" ${isChecked ? "added" : "removed"}`,
                ),
        },
    );

    return (
        <div className="flex grow flex-col gap-1 overflow-y-scroll p-4">
            {Array.from(availableCols.values()).map((col) => (
                <label
                    key={col.type}
                    className="group flex items-center gap-4 rounded-lg border px-4 py-3 pr-2 hover:bg-muted/50"
                >
                    <Checkbox
                        checked={addedColTypes.has(col.type)}
                        onCheckedChange={(isChecked) =>
                            handleCheckedChange.submit(
                                col.type,
                                isChecked === true,
                            )
                        }
                    />
                    <div className="flex grow items-center gap-2">
                        <ProbeTypeIcon
                            type={col.content_type}
                            className="shrink-0"
                        />
                        <p className="font-headline font-medium">
                            {col.description}
                        </p>
                    </div>
                    <LaunchStatusBadge status={col.launch_status} />
                </label>
            ))}
        </div>
    );
};

const LaunchStatusBadge = (props: { status: FindingGroupLaunchStatus }) => {
    switch (props.status) {
        case FindingGroupLaunchStatus.deprecated:
        case FindingGroupLaunchStatus.launched:
            return null;
        case FindingGroupLaunchStatus.beta:
            return (
                <Badge variant="beta" className="text-green-500">
                    BETA
                </Badge>
            );
        case FindingGroupLaunchStatus.internal:
            return (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            );
        default:
            return props.status satisfies never;
    }
};
