import { ReactNode, useEffect } from "react";

import {
    AsyncFindingGroup,
    DocumentFindingGroup,
    FindingGroupStatus,
} from "@/api/types";
import { DocumentFindingContent } from "@/components/document/finding-content";
import { useGridView } from "@/hooks/use-grid-view-context";
import { AsyncState } from "@/utils/async-value";
import { memoize } from "@/utils/fn";

export const FindingGroup = ({
    findingGroup,
    loader,
    fallback,
}: {
    findingGroup?: AsyncFindingGroup;
    loader?: ReactNode;
    fallback?: ReactNode;
}) => {
    const fetchFindings = useGridView((s) => memoize(s.fetchFindings));
    useEffect(() => {
        if (
            findingGroup !== undefined &&
            findingGroup.status === FindingGroupStatus.completed &&
            findingGroup.findings.state === AsyncState.initial
        ) {
            fetchFindings(findingGroup.id);
        }
    }, []);

    if (findingGroup === undefined) return fallback;
    switch (findingGroup.status) {
        case FindingGroupStatus.processing:
            return loader;
        case FindingGroupStatus.completed:
            switch (findingGroup.findings.state) {
                case AsyncState.initial:
                case AsyncState.fetching:
                    return loader;
                case AsyncState.error:
                    return fallback;
                case AsyncState.success:
                    return (
                        <DocumentFindingContent
                            findingGroup={
                                {
                                    ...findingGroup,
                                    findings: findingGroup.findings.value,
                                } as DocumentFindingGroup
                            }
                        />
                    );
                default:
                    return findingGroup.findings satisfies never;
            }
        case FindingGroupStatus.failed:
        case FindingGroupStatus.cancelled:
            return fallback;
        default:
            return findingGroup.status satisfies never;
    }
};
