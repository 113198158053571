import { ArrowLeft, Check } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { ErrorAlert } from "@/components/error-alert";
import { ResetPasswordForm } from "@/components/forms/reset-password";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";
import { ForgotPasswordRoute } from "@/routes/auth/forgot-password";
import { isTokenExpired } from "@/utils/jwt";

export const ResetPasswordRoute = () => {
    usePageTitle("Reset Password");
    const api = useApi();

    const token = useMemo<string | null>(() => {
        const queryParams = new URLSearchParams(document.location.search);
        const token = queryParams.get("nonce");
        return token && !isTokenExpired(token) ? token : null;
    }, []);

    const action = useAsyncState(async (password: string) => {
        await api.reset_password(token!, password);
        return true;
    });

    if (!token) {
        return (
            <ForgotPasswordRoute initialError="Your password reset link expired. Please request a new one." />
        );
    }

    return (
        <div className="min-w-96 space-y-10">
            <h2 className="text-center font-headline text-2xl font-semibold">
                Reset Password
            </h2>
            {action.error && <ErrorAlert error={action.error} />}
            {action.data === true ? (
                <Alert variant="success">
                    <Check className="h-4 w-4" />
                    <AlertTitle>Success</AlertTitle>
                    <AlertDescription>
                        Your password has been updated.
                    </AlertDescription>
                </Alert>
            ) : (
                <ResetPasswordForm action={action} />
            )}
            <div className="text-center">
                <Button variant="link" asChild>
                    <Link to="/login">
                        <ArrowLeft className="mr-2 h-4 w-4" />
                        Back to the login page
                    </Link>
                </Button>
            </div>
        </div>
    );
};
