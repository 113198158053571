import { ChevronDown, ChevronUp, RefreshCw } from "lucide-react";
import { memo, useEffect, useMemo } from "react";
import { toast } from "sonner";

import {
    APActionType,
    AsyncFindingGroup,
    FindingContentType,
    FindingGroupStatus,
    FindingGroupType,
    GenericFindingContent,
} from "@/api/types";
import { CellState } from "@/components/analyze/cell-state";
import { AsyncButton } from "@/components/async-button";
import { ActionButton } from "@/components/document-table/action-button";
import { Markdown } from "@/components/markdown";
import { Badge, BadgeProps } from "@/components/ui/badge";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { AsyncState } from "@/utils/async-value";
import { firstX } from "@/utils/collection";
import { preventDefault } from "@/utils/dom";
import { getRawFindingContent } from "@/utils/finding-group";
import { memoize } from "@/utils/fn";
import { capitalize, notEmptyOrNull, simpleHash } from "@/utils/string-helpers";

// future renderer supporting a list of generic finding types
// assumption is all findings have the same content_type
export const GenericFindingGroup = ({
    findings,
}: {
    findings: GenericFindingContent[];
}) => {
    if (findings.length === 0) {
        return <CellState>No Findings</CellState>;
    }

    const content_type = firstX(findings).content_type;
    const children = findings.flatMap((finding) => (
        <FindingTag key={finding.id} finding={finding} />
    ));
    switch (content_type) {
        case FindingContentType.list_item:
            return (
                <ul className="list-outside list-disc pl-4" data-bw-actions>
                    {children}
                </ul>
            );
        case FindingContentType.boolean:
        case FindingContentType.full_text:
        case FindingContentType.long_text:
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.short_text:
        case FindingContentType.titled_long_text:
        case FindingContentType.titled_list:
        case FindingContentType.unstructured:
            return (
                <div className="flex flex-wrap gap-1" data-bw-actions>
                    {children}
                </div>
            );
        default:
            return content_type satisfies never;
    }
};

export const FindingContent = ({
    finding,
    className,
}: {
    finding: GenericFindingContent;
    className?: string;
}) => {
    const [devMode] = useDevMode();
    switch (finding.content_type) {
        case FindingContentType.list_item:
            return <li>{finding.text}</li>;
        case FindingContentType.boolean:
            return notEmptyOrNull(finding.text) && finding.value ? (
                <p>{finding.text}</p>
            ) : null;
        case FindingContentType.short_text:
        case FindingContentType.full_text:
        case FindingContentType.long_text:
            return (
                <Markdown className={cn("basis-full text-sm", className)}>
                    {finding.text}
                </Markdown>
            );
        case FindingContentType.titled_long_text:
            return (
                <div className="basis-full">
                    <p className="font-headline font-bold">{finding.title}</p>
                    <p>{finding.text}</p>
                </div>
            );
        case FindingContentType.titled_list:
            return (
                <>
                    <p className="font-headline font-bold">{finding.title}</p>
                    <ul className="space-y-2">
                        {finding.key_points.map((item, i) => (
                            <li key={simpleHash(item + i)}>{item}</li>
                        ))}
                    </ul>
                </>
            );
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return null;
        default:
            if (devMode) {
                return (
                    <pre className="rounded bg-muted p-2 text-[9px]">
                        {JSON.stringify(finding, null, 2)}
                    </pre>
                );
            }
            return finding satisfies never;
    }
};

export const FindingGroupContent = memo(
    ({ findingGroup }: { findingGroup: AsyncFindingGroup }) => {
        const fetchFindings = useGridView((s) => memoize(s.fetchFindings));
        useEffect(() => {
            if (
                findingGroup.status === FindingGroupStatus.completed &&
                findingGroup.findings.state === AsyncState.initial
            ) {
                fetchFindings(findingGroup.id);
            }
        }, [findingGroup.status, findingGroup.findings.state]);
        const retryFailedFindingGroup = useGridView(
            (s) => s.retryFailedFindingGroup,
        );
        const retry = useAsyncState(
            async () => await retryFailedFindingGroup(findingGroup.id),
            { onError: () => toast.error("Failed to regenerate the analysis") },
        );

        switch (findingGroup.status) {
            case FindingGroupStatus.processing:
                return <CellState variant="loading">Analyzing...</CellState>;
            case FindingGroupStatus.cancelled:
            case FindingGroupStatus.failed:
                return (
                    <AsyncButton
                        action={retry}
                        variant="ghost-destructive"
                        size="xs"
                        className="text-destructive"
                    >
                        <RefreshCw className="mr-2 size-4" />
                        Analysis Failed. Retry...
                    </AsyncButton>
                );
            case FindingGroupStatus.completed:
                switch (findingGroup.findings.state) {
                    case AsyncState.initial:
                    case AsyncState.fetching:
                        return (
                            <CellState variant="loading">Loading...</CellState>
                        );
                    case AsyncState.error:
                        return (
                            <CellState variant="loading">
                                Failed to load analysis
                            </CellState>
                        );
                    case AsyncState.success:
                        // Special case: Executive summary
                        if (
                            findingGroup.type ===
                            FindingGroupType.executive_summary
                        ) {
                            return (
                                <TextFindings
                                    items={findingGroup.findings.value
                                        .filter(
                                            (f) =>
                                                f.content_type ===
                                                FindingContentType.short_text,
                                        )
                                        .map((f) => [f.id, f.text])}
                                    maxItems={1}
                                />
                            );
                        }
                        return (
                            <GenericFindingGroup
                                findings={findingGroup.findings.value}
                            />
                        );
                }
            default:
                return findingGroup.status satisfies never;
        }
    },
);

const TextFindings = ({
    items,
    maxItems = Infinity,
}: {
    items: [string, string][];
    maxItems?: number;
}) => {
    const [collapsed, actions] = useBoolean(items.length > maxItems);
    const numDisplay = collapsed ? maxItems : items.length;
    return (
        <div className="space-y-2">
            {items.slice(0, numDisplay).map(([id, content]) => (
                <p key={id}>{content}</p>
            ))}
            {items.length > maxItems && (
                <Badge variant="outline" role="button" onClick={actions.toggle}>
                    {collapsed ? (
                        <>
                            {items.length - maxItems} More
                            <ChevronDown className="ml-1.5 size-3" />
                        </>
                    ) : (
                        <>
                            Show Less
                            <ChevronUp className="ml-1.5 size-3" />
                        </>
                    )}
                </Badge>
            )}
        </div>
    );
};

const FindingTag = ({ finding }: { finding: GenericFindingContent }) => {
    const variant = useMemo((): BadgeProps["variant"] => {
        switch (finding.content_type) {
            case FindingContentType.boolean:
                return finding.value ? "boolean_true" : "boolean_false";
            case FindingContentType.full_text:
            case FindingContentType.long_text:
            case FindingContentType.short_text:
            case FindingContentType.titled_long_text:
            case FindingContentType.numerical:
            case FindingContentType.numerical_full_text:
            case FindingContentType.list_item:
            case FindingContentType.titled_list:
            case FindingContentType.unstructured:
                return "table_tag";
            default:
                return finding satisfies never;
        }
    }, [finding.content_type]);
    const badge_content = useMemo(() => {
        switch (finding.content_type) {
            case FindingContentType.boolean:
                return finding.value ? "Yes" : "No";
            case FindingContentType.full_text:
            case FindingContentType.long_text:
            case FindingContentType.numerical:
            case FindingContentType.numerical_full_text:
            case FindingContentType.short_text:
            case FindingContentType.titled_long_text:
            case FindingContentType.list_item:
            case FindingContentType.unstructured:
                return finding.tag;
            case FindingContentType.titled_list:
                return finding.tag ?? capitalize(finding.title);
            default:
                return finding satisfies never;
        }
    }, [finding.content_type]);
    const badgeOnly = useMemo(() => {
        switch (finding.content_type) {
            case FindingContentType.boolean:
                return finding.value === false;
            case FindingContentType.full_text:
            case FindingContentType.long_text:
            case FindingContentType.numerical:
            case FindingContentType.numerical_full_text:
            case FindingContentType.short_text:
            case FindingContentType.titled_long_text:
            case FindingContentType.list_item:
            case FindingContentType.unstructured:
            case FindingContentType.titled_list:
                return false;
            default:
                return finding satisfies never;
        }
    }, [finding.content_type]);

    const finding_content = <FindingContent finding={finding} />;
    if (!badge_content) {
        return finding_content;
    }

    const renderBadge = (asButton: boolean = false) => (
        <Badge
            variant={variant}
            size="tag"
            role={asButton ? "button" : undefined}
        >
            {badge_content}
            {asButton ? <ChevronDown className="ml-1.5 size-4" /> : undefined}
        </Badge>
    );

    if (badgeOnly) {
        return renderBadge(!badgeOnly);
    }

    const highlightText = getRawFindingContent(finding);
    return (
        <Popover>
            <PopoverTrigger asChild>{renderBadge(!badgeOnly)}</PopoverTrigger>
            <PopoverContent
                className="w-[35vw] space-y-2 overflow-y-scroll p-2 text-sm"
                style={{ maxHeight: "var(--radix-popper-available-height)" }}
                onOpenAutoFocus={preventDefault}
                collisionPadding={5}
                data-bw-actions
            >
                <div className="pointer-events-none">{renderBadge()}</div>
                <Separator />
                {finding_content}
                {highlightText && (
                    <>
                        <Separator />
                        <div className="flex justify-end gap-2">
                            <ActionButton
                                type={APActionType.tell_me_more}
                                highlightText={highlightText}
                            />
                            <ActionButton
                                type={APActionType.implications}
                                highlightText={highlightText}
                            />
                        </div>
                    </>
                )}
            </PopoverContent>
        </Popover>
    );
};
