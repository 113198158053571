import { PropsWithChildren, createContext } from "react";
import useSWR, { SWRResponse } from "swr";

import { AccountSettings } from "@/api/types";
import { useApi } from "@/hooks/use-api";

type TContext = {
    settings: AccountSettings;
    activeUserCount: SWRResponse<number, Error, { suspense: true }>;
};

export const ManageUserContext = createContext({} as TContext);

export const ManageUserContextProvider = (props: PropsWithChildren) => {
    const api = useApi();
    const { data: settings } = useSWR(
        ["account_settings"],
        async () => api.fetch_account_settings(),
        { suspense: true },
    );

    const activeUserCount = useSWR(
        ["active_user_count"],
        async () =>
            (
                await api.fetch_account_users({
                    active: true,
                    pagination: { size: 1 },
                })
            ).count,
        { suspense: true },
    );

    return (
        <ManageUserContext.Provider
            value={{ settings, activeUserCount }}
            {...props}
        />
    );
};
