import {
    useState,
    useCallback,
    useLayoutEffect,
    useMemo,
    forwardRef,
    ForwardedRef,
    HTMLAttributes,
} from "react";

import { useForwardRef } from "@/hooks/use-forward-ref";
import { cn } from "@/lib/utils";
import { findParentWithOverflowHidden } from "@/utils/dom";
import {
    Rect,
    getAdjustedBoundingClientRect,
    fromDOMRect,
    translateY,
    translate,
    rectBottom,
    rectHCenter,
    getInsersection,
    translateX,
    Position,
    rectRight,
} from "@/utils/geometry";

const PADDING = 10;
const ANCHOR_GAP = 4;

const getTransform = (pos: Position) =>
    `translate3d(${Math.round(pos.left)}px,${Math.round(pos.top)}px,0)`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    anchor: Rect;
}

export const PopoverPosition = forwardRef(
    (
        { anchor, children, className, style, ...props }: Props,
        forwardRef: ForwardedRef<HTMLDivElement>,
    ) => {
        const ref = useForwardRef(forwardRef);
        const [box, setBox] = useState<Rect | undefined>();
        const [boundingBox, setBoundingBox] = useState<Rect | undefined>();

        const updateBoxes = useCallback(() => {
            const el = ref.current;
            if (el) {
                setBox(getAdjustedBoundingClientRect(el));
                const parentEl = findParentWithOverflowHidden(ref.current);
                if (parentEl) {
                    setBoundingBox(
                        fromDOMRect(parentEl.getBoundingClientRect()),
                    );
                }
            }
        }, [ref.current, setBox, setBoundingBox]);

        useLayoutEffect(() => {
            updateBoxes();
            if (ref.current) {
                const mutationObserver = new MutationObserver(updateBoxes);
                mutationObserver.observe(ref.current, {
                    childList: true,
                    subtree: true,
                });
                return () => mutationObserver.disconnect();
            }
        }, [ref.current, updateBoxes]);

        const transform = useMemo(() => {
            if (!box) return getTransform(translateY(anchor, 4));
            let cBox = translate(box, {
                top: rectBottom(anchor) + ANCHOR_GAP,
                left: rectHCenter(anchor) - box.width / 2,
            });
            if (boundingBox) {
                const intersection = getInsersection(cBox, boundingBox);

                // move box above anchor if intersects at bottom
                if (intersection & 0b00001000 && cBox.top > anchor.height) {
                    cBox = translateY(
                        cBox,
                        -(cBox.height + anchor.height + ANCHOR_GAP * 2),
                    );
                }

                // intersects right
                if (intersection & 0b00100000) {
                    return getTransform(
                        translateX(
                            cBox,
                            rectRight(boundingBox) - rectRight(cBox) - PADDING,
                        ),
                    );
                }

                // intersects left
                if (intersection & 0b00000010) {
                    return getTransform(
                        translateX(
                            cBox,
                            boundingBox.left + PADDING - cBox.left,
                        ),
                    );
                }
            }

            return getTransform(cBox);
        }, [anchor, box, boundingBox]);

        return (
            <div
                ref={ref}
                className={cn("absolute left-0 top-0", className)}
                style={{ ...style, transform, zIndex: 9999 }}
                {...props}
            >
                {children}
            </div>
        );
    },
);
