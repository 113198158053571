import { Braces, ChevronDown, Edit, RefreshCcw, Trash } from "lucide-react";
import { memo, PropsWithChildren, SyntheticEvent } from "react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import { RemoveColumnPayload, UpdateProbePayload } from "@/api/rest";
import {
    Column,
    ColumnType,
    FindingGroupType,
    Probe,
    ProbeType,
    UUID,
} from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { Debugger } from "@/components/debug/debugger";
import { ColumnFocus } from "@/components/document-table/column-focus";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Markdown } from "@/components/markdown";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useApi } from "@/hooks/use-api";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useGridView } from "@/hooks/use-grid-view-context";
import { AsyncState } from "@/utils/async-value";
import { getColumId } from "@/utils/columns";
import { getProbeTitle, maybeGetFindingGroup } from "@/utils/finding-group";
import { bind, nonNull } from "@/utils/fn";

const RefreshColumn = ({
    findingGroupType,
    probe,
}: {
    findingGroupType: FindingGroupType;
    probe?: Probe;
}) => {
    const invalidateColumn = useGridView((s) => s.invalidateColumn);
    const action = useAsyncState(async (e: SyntheticEvent) => {
        e.stopPropagation();
        return await invalidateColumn(findingGroupType, probe?.id);
    });
    return (
        <AsyncButton
            variant="ghost"
            size="sm"
            type="button"
            action={action}
            className="w-full justify-start"
        >
            <RefreshCcw className="mr-2 size-4" />
            Refresh Findings
        </AsyncButton>
    );
};

const CopyColumnDebug = ({
    findingGroupType,
    probe,
}: {
    findingGroupType: FindingGroupType;
    probe?: Probe;
}) => {
    const api = useApi();
    const all = useGridView(
        useShallow((s) =>
            Array.from(s.documents_to_finding_groups.entries()).map(
                ([doc_id, fg_ids]) => ({
                    document_info: s.documents.get(doc_id)!,
                    finding_group: fg_ids
                        .map((id) => s.finding_groups.get(id))
                        .filter(nonNull)
                        .find(
                            (fg) =>
                                fg.type === findingGroupType &&
                                fg.probe?.id === probe?.id,
                        ),
                }),
            ),
        ),
    );
    const action = useAsyncState(
        async () => {
            const allFetched = await Promise.all(
                all.map(async ({ document_info, finding_group }) => {
                    if (
                        finding_group == null ||
                        finding_group.findings.state === AsyncState.success
                    ) {
                        return {
                            document_info,
                            finding_group: maybeGetFindingGroup(finding_group),
                        };
                    }
                    const result = await api.fetch_analysis_by_id(
                        finding_group.id,
                    );
                    return {
                        document_info: document_info,
                        finding_group: result,
                    };
                }),
            );
            const data = JSON.stringify(allFetched, null, 2);
            await navigator.clipboard.writeText(data);
        },
        {
            onSuccess: () => toast.success("Column JSON copied to clipboard"),
            onError: () =>
                toast.error("Failed to load & copy all finding groups"),
        },
    );

    return (
        <AsyncButton
            variant="ghost"
            size="sm"
            className="w-full justify-start"
            action={action}
        >
            <Braces className="mr-2 size-4" />
            Copy Column Debug
        </AsyncButton>
    );
};

const deletionPayloadForColumn = (c: Column): RemoveColumnPayload => {
    switch (c.column_type) {
        case ColumnType.system:
            return {
                column_type: ColumnType.system,
                finding_group_type: c.finding_group_type,
            };
        case ColumnType.user_defined:
            return {
                column_type: ColumnType.user_defined,
                id: c.details.id,
            };
        default:
            return c satisfies never;
    }
};

export const ColumnHeader = memo(({ column }: { column: Column }) => {
    const updateColumn = useGridView((s) => s.updateColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);
    const availableColumns = useGridView((s) => s.availableColumns);

    const deleteAction = useAsyncState(
        async () => await deleteColumn(deletionPayloadForColumn(column)),
    );

    switch (column.column_type) {
        case ColumnType.system:
            return (
                <>
                    <ColumnFocus columnKey={getColumId(column)} />
                    <SystemHeader
                        title={
                            availableColumns.get(column.finding_group_type)
                                ?.description ?? "n/a"
                        }
                        content_type={
                            availableColumns.get(column.finding_group_type)
                                ?.content_type
                        }
                        deleteAction={deleteAction}
                    >
                        <Debugger>
                            <CopyColumnDebug
                                findingGroupType={column.finding_group_type}
                            />
                            <RefreshColumn
                                findingGroupType={column.finding_group_type}
                            />
                        </Debugger>
                    </SystemHeader>
                </>
            );
        case ColumnType.user_defined:
            return (
                <>
                    <ColumnFocus columnKey={getColumId(column)} />
                    <ProbeHeader
                        probe={column.details}
                        updateColumn={updateColumn}
                        deleteAction={deleteAction}
                    >
                        <Debugger>
                            <CopyColumnDebug
                                findingGroupType={column.finding_group_type}
                                probe={column.details}
                            />
                            <RefreshColumn
                                findingGroupType={column.finding_group_type}
                                probe={column.details}
                            />
                        </Debugger>
                    </ProbeHeader>
                </>
            );
    }
});

type SystemHeaderProps = {
    title: string;
    content_type?: ProbeType;
    deleteAction: UseAsyncState<[], unknown>;
};

const SystemHeader = (props: PropsWithChildren<SystemHeaderProps>) => (
    <Popover>
        <PopoverTrigger asChild>
            <Button variant="ghost" size="sm" className="gap-2">
                {props.content_type && (
                    <ProbeTypeIcon type={props.content_type} />
                )}
                <span>{props.title}</span>
                <ChevronDown className="size-4" />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-1 p-1">
            {props.children}
            <AsyncButton
                variant="ghost-destructive"
                size="sm"
                className="justify-start"
                action={props.deleteAction}
            >
                <Trash className="mr-2 size-4" />
                Remove Column
            </AsyncButton>
        </PopoverContent>
    </Popover>
);

type ProbeHeaderProps = {
    probe: Probe;
    deleteAction: UseAsyncState<[], unknown>;
    updateColumn: (
        probe_id: UUID,
        payload: UpdateProbePayload,
    ) => Promise<void>;
};

const ProbeHeader = ({
    probe,
    deleteAction,
    children,
}: PropsWithChildren<ProbeHeaderProps>) => {
    const editProbe = useColumnSheet((s) => s.editProbe);
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="sm" className="gap-2">
                    <ProbeTypeIcon type={probe.type} />
                    <span>{getProbeTitle(probe)}</span>
                    <ChevronDown className="size-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-96 p-0">
                <div className="space-y-2 p-4">
                    <p className="text-sm font-bold">{probe.name}</p>
                    <Markdown className="text-sm">{probe.prompt}</Markdown>
                </div>
                <div className="flex flex-col gap-1 border-t p-1">
                    {children}
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={bind(editProbe, probe)}
                        className="justify-start gap-2"
                    >
                        <Edit className="size-4" />
                        Edit
                    </Button>
                    <AsyncButton
                        variant="ghost-destructive"
                        size="sm"
                        action={deleteAction}
                        className="justify-start"
                    >
                        <Trash className="mr-2 size-4" />
                        Remove Column
                    </AsyncButton>
                </div>
            </PopoverContent>
        </Popover>
    );
};
