import { BookOpenCheck, EllipsisVertical, Trash } from "lucide-react";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";
import { useShallow } from "zustand/react/shallow";

import { ColumnType, Probe, UUID } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuItem,
    DropdownMenuContent,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";

export const ProbesLibrary = () => {
    const api = useApi();
    const {
        data: probes,
        isLoading,
        mutate,
    } = useSWRImmutable(["probes"], async () => await api.fetch_probes(), {
        revalidateOnMount: true,
    });
    const addedProbeIDs = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.columns.values())
                        .filter(
                            (c) => c.column_type === ColumnType.user_defined,
                        )
                        .map((c) => c.details.id),
                ),
        ),
    );

    const addColumn = useGridView((s) => s.addColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);
    const handleCheckedChange = useAsyncState(
        async (probe: Probe, isChecked: boolean) => {
            if (isChecked) {
                await addColumn({
                    column_type: ColumnType.user_defined,
                    id: probe.id,
                    schema_id: probe.schema_id,
                    details: probe,
                });
            } else {
                await deleteColumn({
                    column_type: ColumnType.user_defined,
                    id: probe.id,
                });
            }
            return { isChecked, probe };
        },
        {
            onSuccess: ({ isChecked, probe }) =>
                toast.success(
                    `Column "${probe.name}" ${isChecked ? "added" : "removed"}`,
                ),
        },
    );

    const hideAction = useAsyncState(
        async (schema_id: UUID) => await api.hide_probe(schema_id),
        { onSuccess: () => mutate() },
    );

    if (!isLoading && (probes ?? []).length === 0) {
        return (
            <div className="m-4 flex grow flex-col items-center justify-center gap-8 rounded-lg border p-4">
                <span className="rounded-full bg-muted p-6">
                    <BookOpenCheck className="size-10 text-muted-foreground" />
                </span>
                <div className="space-y-1 text-center">
                    <p className="font-semibold">Your library is empty</p>
                    <p className="text-sm">Start by creating a new column</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex grow flex-col gap-2 overflow-y-scroll p-4">
            {isLoading && <Loader />}
            {(probes ?? []).map((probe) => (
                <label
                    key={probe.id}
                    className="group flex items-center gap-4 rounded-lg border px-4 py-3 pr-2 hover:bg-muted/50"
                >
                    <Checkbox
                        checked={addedProbeIDs.has(probe.id)}
                        onCheckedChange={(isChecked) =>
                            handleCheckedChange.submit(
                                probe,
                                isChecked === true,
                            )
                        }
                    />
                    <div className="grow space-y-1">
                        <div className="flex items-center gap-2">
                            <ProbeTypeIcon
                                type={probe.type}
                                className="shrink-0"
                            />
                            <p className="font-headline font-medium">
                                {probe.name}
                            </p>
                        </div>
                        <p className="line-clamp-8 text-sm text-muted-foreground">
                            {probe.prompt}
                        </p>
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="ghost"
                                size="icon-sm"
                                className="shrink-0"
                            >
                                <EllipsisVertical className="size-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent collisionPadding={5}>
                            <DropdownMenuItem
                                className="gap-2 transition-colors focus:bg-red-50 focus:text-destructive"
                                onClick={hideAction.getEventHandler(
                                    probe.schema_id,
                                )}
                            >
                                <Trash className="size-4" />
                                Remove From Library
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </label>
            ))}
        </div>
    );
};

const Loader = () =>
    Array(10)
        .fill(1)
        .map((_, i) => (
            <div className="flex min-h-9 items-center gap-4 px-3" key={i}>
                <Skeleton className="size-4" />
                <Skeleton className="h-2.5 w-28" />
            </div>
        ));
