import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { useStore } from "zustand";

import { ContextStatus, UUID } from "@/api/types";
import { WebsocketClientEvent } from "@/api/ws/websocket-client";
import { contextLoad } from "@/api/ws/websocket-outbound-events";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { useAppContext } from "@/hooks/use-app-context";
import { useWebsocket } from "@/hooks/use-websocket";
import { ContextStore, createContextStore } from "@/stores/context";

export const ContextContext = createContext<ContextStore | null>(null);

type Props = {
    messageID: UUID;
};

export const ContextContextProvider = ({
    messageID,
    ...props
}: PropsWithChildren<Props>) => {
    const isLoadingCtx = useRef(false);
    const store = useRef<ContextStore>();
    if (!store.current) {
        store.current = createContextStore();
    }

    const websocket = useAppContext((s) => s.websocket);
    const status = useStore(store.current, (s) => s.status);
    const setLoading = useStore(store.current, (s) => s.setLoading);
    const setError = useStore(store.current, (s) => s.setError);
    const setLoaded = useStore(store.current, (s) => s.setLoaded);
    const updateError = useStore(store.current, (s) => s.updateError);
    const reset = useStore(store.current, (s) => s.reset);
    const setUpdating = useStore(store.current, (s) => s.setUpdating);
    const updateSuccess = useStore(store.current, (s) => s.updateSuccess);

    const wsFilter = useCallback(
        <T extends { message_id: UUID }>(evt: T): boolean =>
            evt.message_id === messageID,
        [messageID],
    );

    useWebsocket((ws) => {
        // context Status events
        ws.onIf(
            IncomingWebsocketEventType.context_load_success,
            wsFilter,
            ({ document_ids }) => setLoaded(document_ids),
        );
        ws.onIf(
            IncomingWebsocketEventType.context_load_error,
            wsFilter,
            setError,
        );
        ws.onIf(
            IncomingWebsocketEventType.context_update_ack,
            wsFilter,
            ({ method, document_ids }) => setUpdating(method, document_ids),
        );
        ws.onIf(
            IncomingWebsocketEventType.context_update_success,
            wsFilter,
            ({ method, document_ids }) => updateSuccess(method, document_ids),
        );
        ws.onIf(
            IncomingWebsocketEventType.context_update_error,
            wsFilter,
            ({ document_ids }) => updateError(document_ids),
        );

        // reset context status
        ws.registerSocketEvent(WebsocketClientEvent.SOCKET_DISCONNECTED, () => {
            isLoadingCtx.current = false;
            reset();
        });
    });

    // load context if status is uninitialized and websocket is authenticated
    useEffect(() => {
        if (websocket && status === ContextStatus.UNINITIALIZED) {
            if (isLoadingCtx.current === false) {
                isLoadingCtx.current = true;
                websocket.send(contextLoad(messageID));
            }
        }
    }, [websocket, status, setLoading, messageID]);

    return <ContextContext.Provider value={store.current} {...props} />;
};
