import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { forwardRef, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const headlineVariants = cva("font-headline font-bold text-balance", {
    variants: {
        level: {
            1: "text-7xl @container @5xl:text-9xl",
            2: "text-5xl",
            3: "text-2xl",
            4: "uppercase",
        },
        separator: {
            true: "border-t border-foreground pt-2 ",
            false: "",
        },
    },
    defaultVariants: {
        level: 1,
        separator: false,
    },
});

export interface HeadlineProps
    extends HTMLAttributes<HTMLParagraphElement>,
        VariantProps<typeof headlineVariants> {
    highlighted?: boolean;
    asChild?: boolean;
}

export const Headline = forwardRef<HTMLParagraphElement, HeadlineProps>(
    (
        {
            className,
            level,
            separator,
            highlighted = false,
            asChild = false,
            children,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "p";
        return (
            <Comp
                className={cn(
                    headlineVariants({ level, separator, className }),
                )}
                ref={ref}
                {...props}
            >
                {highlighted ? (
                    <span className="bg-gradient-to-b from-transparent from-65% to-brightwave/50 to-65% group-hover:bg-gradient-to-b dark:to-brightwave-800">
                        {children}
                    </span>
                ) : (
                    children
                )}
            </Comp>
        );
    },
);
