import { getYear, parseISO } from "date-fns";

import {
    APIContextItem,
    ContextItem,
    ContextType,
    EarningsTranscriptContextItem,
    Encoded,
    EncodedContextItem,
    ExistingDocumentContextItem,
    ExistingDocumentContextItemWithInfo,
    SECFilingContextItem,
    UserUploadContextItem,
} from "@/api/types";
import { pipe } from "@/utils/fn";

export const isUserUpload = (
    item: ContextItem,
): item is UserUploadContextItem =>
    "type" in item && item.type === ContextType.USER_UPLOAD;

export const isSECFiling = (
    item: ContextItem,
): item is Encoded<SECFilingContextItem> =>
    "encoded" in item && item.data.type === ContextType.EXTERNAL_SEC_FILING;

export const isEarningsCall = (
    item: ContextItem,
): item is Encoded<EarningsTranscriptContextItem> =>
    "encoded" in item &&
    item.data.type === ContextType.EXTERNAL_EARNINGS_TRANSCRIPT;

export const isExistingDocument = (
    item: ContextItem,
): item is ExistingDocumentContextItem =>
    "type" in item && item.type === ContextType.EXISTING_DOCUMENT;

const quarteryFilingTypes = new Set(["10-K", "10-Q"]);
export const isQuarterlyFiling = (
    item: ContextItem,
): item is Encoded<SECFilingContextItem> =>
    isSECFiling(item) && quarteryFilingTypes.has(item.data.form_type);

export const serialize = (item: ContextItem): string => {
    if (isUserUpload(item)) {
        return btoa(
            JSON.stringify({
                type: ContextType.USER_UPLOAD,
                upload_id: item.upload_id,
            }),
        );
    }
    if (isExistingDocument(item)) {
        return btoa(
            JSON.stringify({
                type: ContextType.EXISTING_DOCUMENT,
                document_id: item.document_id,
            }),
        );
    }
    return item.encoded;
};

export const deserialize = (encoded: string): ContextItem | null => {
    const decoded = atob(encoded);
    try {
        const data = JSON.parse(decoded);
        if (isUserUpload(data)) {
            return data;
        }
        return { encoded, data };
    } catch (_) {
        return null;
    }
};

export const getContextItemKey = (item: ContextItem): string =>
    isUserUpload(item)
        ? item.upload_id
        : isExistingDocument(item)
          ? item.document_id
          : item.data.unique_id;

export const getContextType = (item: ContextItem): ContextType =>
    isUserUpload(item) || isExistingDocument(item) ? item.type : item.data.type;

export const isContexType =
    (type: ContextType) =>
    (item: ContextItem): boolean =>
        getContextType(item) === type;

export const encodeContextItem = (
    item: ContextItem,
): EncodedContextItem | UserUploadContextItem | ExistingDocumentContextItem => {
    if (isUserUpload(item)) {
        return { type: item.type, upload_id: item.upload_id };
    }
    if (isExistingDocument(item)) {
        return { type: item.type, document_id: item.document_id };
    }
    return encodeAPIContextItem(item);
};

export const encodeAPIContextItem = (
    item: APIContextItem,
): EncodedContextItem => ({
    type: "encoded_context_item",
    encoded: item.encoded,
});

export const getFilingDate = (
    item: APIContextItem | ExistingDocumentContextItemWithInfo,
): Date => {
    if (isExistingDocument(item)) {
        return item.content_date ? parseISO(item.content_date) : new Date();
    }
    switch (item.data.type) {
        case ContextType.EXTERNAL_EARNINGS_TRANSCRIPT:
            return parseISO(item.data.filed_at);
        case ContextType.EXTERNAL_SEC_FILING:
            return parseISO(item.data.filed_at);
    }
};
export const getFilingYear = pipe(getFilingDate, getYear);

export const isUsable = (item: APIContextItem): boolean => {
    switch (item.data.type) {
        case ContextType.EXTERNAL_EARNINGS_TRANSCRIPT:
            return true;
        case ContextType.EXTERNAL_SEC_FILING:
            return item.data.usable;
    }
};
