import { ArrowUpRight } from "lucide-react";
import {
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    HtmlHTMLAttributes,
} from "react";

import { DocumentInfo } from "@/api/types";
import { ExternalRef } from "@/components/action-message/external-ref";
import { CitationNumber } from "@/components/citation-number";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { formatDate } from "@/utils/time";

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: DocumentInfo;
    citations?: number[];
    dark?: boolean;
}

export const DocumentReference = forwardRef(
    (
        { info, citations, dark = false, className, ...props }: Props,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "group flex min-h-12 cursor-pointer items-center gap-2 overflow-hidden rounded p-2 leading-none transition-colors hover:bg-muted",
                    dark && "hover:bg-foreground",
                    className,
                )}
                {...props}
            >
                <div className="grow space-y-1">
                    <p className="line-clamp-1 font-headline text-sm font-bold">
                        {(citations ?? []).map((i) => (
                            <CitationNumber key={i} className="mx-0 mr-2">
                                {i}
                            </CitationNumber>
                        ))}
                        {info.title}
                    </p>
                    <div className="flex items-baseline justify-between gap-2">
                        <ExternalRef info={info} className="text-xs" />
                        <p className="text-xs empty:hidden group-hover:hidden">
                            {info.content_date && (
                                <span>{formatDate(info.content_date)}</span>
                            )}
                        </p>
                    </div>
                </div>
                <Button
                    size="sm"
                    variant="primary"
                    className={cn(
                        "hidden rounded-full text-xs group-hover:inline-flex",
                        dark &&
                            "bg-background text-foreground hover:bg-muted dark:text-foreground",
                    )}
                >
                    Summary
                    <ArrowUpRight className="ml-1 size-4" />
                </Button>
            </div>
        );
    },
);
// className="hover:bg-foreground [&>button]:bg-background [&>button]:text-foreground hover:[&>button]:bg-muted [&>button]:dark:text-red-500"
export const DocumentReferenceSkeleton = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div className={cn("space-y-2 px-2 py-3", className)} {...props}>
        <Skeleton className="h-4 w-full" />
        <div className="flex justify-between gap-4">
            <Skeleton className="h-3 w-1/4" />
            <Skeleton className="h-3 w-1/4" />
        </div>
    </div>
);
