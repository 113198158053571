import {
    ForwardedRef,
    HTMLAttributes,
    forwardRef,
    useEffect,
    useRef,
} from "react";

import { useForwardRef } from "@/hooks/use-forward-ref";
import { cn } from "@/lib/utils";

interface ChatScrollContainerProps extends HTMLAttributes<HTMLDivElement> {
    threshold?: number;
    disableAutoScroll?: boolean;
}

export const AutoScrollContainer = forwardRef(
    (
        {
            threshold = 50,
            disableAutoScroll = false,
            className,
            ...props
        }: ChatScrollContainerProps,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const vpRef = useForwardRef<HTMLDivElement>(ref);
        const autoScroll = useRef(true);

        // observe for mutations in dom and scroll to bottom if autoScroll is enabled
        useEffect(() => {
            if (!disableAutoScroll && vpRef.current) {
                const listener = () => {
                    if (autoScroll.current) {
                        vpRef.current?.scrollTo({
                            top: vpRef.current!.scrollHeight,
                        });
                    }
                };
                const observer = new MutationObserver(listener);
                observer.observe(vpRef.current, {
                    characterData: true,
                    childList: true,
                    subtree: true,
                });
                return () => observer.disconnect();
            }
        }, [vpRef, disableAutoScroll]);

        // Observe appending children to root (new action / chat message) to always scroll to end
        useEffect(() => {
            if (!disableAutoScroll && vpRef.current) {
                const listener = (mutations: MutationRecord[]) => {
                    // check if there's a mutation that appended a child at the end of the viewport (new action)
                    const elementAppended = mutations.some(
                        (m) =>
                            m.addedNodes.length > 0 &&
                            m.addedNodes[0].nextSibling === null &&
                            m.target.parentElement === vpRef.current,
                    );
                    if (elementAppended) {
                        vpRef.current?.scrollTo({
                            top: vpRef.current!.scrollHeight,
                        });
                    }
                };
                const observer = new MutationObserver(listener);
                observer.observe(vpRef.current, {
                    childList: true,
                    subtree: true,
                });
                return () => observer.disconnect();
            }
        }, [vpRef, disableAutoScroll]);

        // listen to scroll events to toggle autoScroll when user scrolls up above a certain threshold
        useEffect(() => {
            if (!disableAutoScroll && vpRef.current) {
                const listener = () => {
                    const distanceFromBottom =
                        vpRef.current!.scrollHeight -
                        vpRef.current!.clientHeight -
                        vpRef.current!.scrollTop;
                    autoScroll.current = distanceFromBottom < threshold;
                };
                vpRef.current.addEventListener("scroll", listener);
            }
        }, [vpRef, threshold, disableAutoScroll]);

        return (
            <div
                className={cn("overflow-y-scroll", className)}
                ref={vpRef}
                {...props}
            />
        );
    },
);
