import { ReactNode, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { useShallow } from "zustand/react/shallow";

import { DocumentInfo, UUID } from "@/api/types";
import { Document } from "@/components/document/document";
import { useApi } from "@/hooks/use-api";
import { useGridView } from "@/hooks/use-grid-view-context";
import { mapMaybeGet } from "@/utils/es6-map";
import {
    createAsyncFindingGroup,
    isExecutiveSummary,
    isOpportunities,
    isRisks,
} from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";

type Props = {
    id?: UUID;
    fallback?: ReactNode;
};

export const DocumentContainer = ({ id, fallback }: Props) => {
    const info = useGridView((s) => mapMaybeGet(s.documents, id));
    const findingGroups = useGridView(
        useShallow((s) =>
            Array.from(
                mapMaybeGet(s.documents_to_finding_groups, id)?.values() ?? [],
            )
                .map((id) => s.finding_groups.get(id))
                .filter((s) => s != null),
        ),
    );
    const groups = useMemo(
        () =>
            [
                findingGroups.find(isExecutiveSummary),
                findingGroups.find(isRisks),
                findingGroups.find(isOpportunities),
            ].filter(nonNull),
        [findingGroups],
    );

    if (info === undefined) return fallback;
    if (groups.length < 3) {
        return <DocumentEnsureFindingGroups info={info} />;
    }
    return <Document info={info} findingGroups={groups} />;
};

const DocumentEnsureFindingGroups = (props: { info: DocumentInfo }) => {
    const reportID = useGridView((s) => s.report.id);
    const api = useApi();
    const addFindingGroup = useGridView((s) => s.addFindingGroup);
    const { data } = useSWRImmutable(
        [reportID, props.info.id, "full_document"],
        async ([messageID, documentID]) =>
            await api.fetch_document(messageID, documentID),
        {
            suspense: true,
            onSuccess: (data) => {
                for (const fg of data.finding_groups) {
                    addFindingGroup(props.info.id, fg);
                }
            },
        },
    );

    return (
        <Document
            info={props.info}
            findingGroups={data.finding_groups.map(createAsyncFindingGroup)}
        />
    );
};
