import { FindingGroupType } from "@/api/types";

export enum StaticColumn {
    select = "select",
    document = "document",
    ticker = "ticker",
    doc_type = "doc_type",
    document_status = "document_status",
    content_date = "content_date",
}

export const HIDDEN_COLUMNS: Set<string> = new Set([
    StaticColumn.ticker,
    StaticColumn.doc_type,
    StaticColumn.document_status,
    StaticColumn.content_date,
]);

export const DISABLED_FINDING_GROUP_TYPES = new Set([FindingGroupType.stats]);

export const SORTABLE_COLUMNS = new Set([
    StaticColumn.content_date,
    StaticColumn.ticker,
    StaticColumn.doc_type,
]);
