import { useEffect } from "react";

import { isEmptyOrNull } from "@/utils/string-helpers";

const SEPARATOR = " · ";

export const usePageTitle = (title: string | string[]) => {
    useEffect(() => {
        const fragments = Array.isArray(title) ? title : [title];
        fragments.push("Brightwave");
        document.title = fragments
            .map((s) => s.trim())
            .filter((s) => !isEmptyOrNull(s))
            .join(SEPARATOR);
    }, [title]);
};
