import { Reply } from "lucide-react";
import {
    MouseEvent,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { APActionType } from "@/api/types";
import { Button } from "@/components/ui/button";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";
import { getLineHeight } from "@/utils/css";

const coerceActionType = (s: string): APActionType | null => {
    if (s in APActionType) {
        return APActionType[s as keyof typeof APActionType];
    }
    return null;
};

type Props = {
    node: unknown; // mdast node
    type: string;
    message?: string;
    selected_text?: string;
    response_to?: string;
};

export const ActionDirective = ({
    children,
    node,
    ...props
}: PropsWithChildren<Props>) => {
    const ref = useRef(null);
    const [hover, hoverActions] = useBoolean(false);
    const [lineClamp, setLineClamp] = useState<string>("line-clamp-none");
    const actionType = coerceActionType(props.type);
    const sendAction = useActionsContext((s) => s.send);
    const onSend = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            if (actionType != null) {
                sendAction(actionType, props);
            }
        },
        [sendAction, props],
    );

    const observe = useCallback(
        ([entry]: ResizeObserverEntry[]) => {
            if (!entry) return;
            if (hover) {
                return;
            }
            const lines = Math.ceil(
                entry.contentRect.height / getLineHeight(entry.target),
            );
            switch (lines) {
                case 1:
                    return setLineClamp("line-clamp-1");
                case 2:
                    return setLineClamp("line-clamp-2");
                case 3:
                    return setLineClamp("line-clamp-3");
                case 4:
                    return setLineClamp("line-clamp-4");
                case 5:
                    return setLineClamp("line-clamp-5");
                case 6:
                    return setLineClamp("line-clamp-6");
                case 7:
                    return setLineClamp("line-clamp-7");
                case 8:
                    return setLineClamp("line-clamp-8");
                case 9:
                    return setLineClamp("line-clamp-9");
                default:
                    return setLineClamp("line-clamp-10");
            }
        },
        [setLineClamp],
    );

    useEffect(() => {
        if (!ref.current) return;
        const el = ref.current;
        const resizeObserver = new ResizeObserver(observe);
        resizeObserver.observe(el);
        return () => resizeObserver.unobserve(el);
    }, [ref.current, observe]);

    if (!actionType) return null;

    return (
        <a
            ref={ref}
            href="#"
            onClick={onSend}
            className="flex items-center gap-2 rounded bg-muted p-2 font-headline no-underline"
            onMouseOver={hoverActions.open}
            onMouseOut={hoverActions.close}
        >
            <span className={cn(hover ? lineClamp : "line-clamp-none")}>
                {children}
            </span>
            <Button size="sm" asChild className={cn(!hover && "hidden")}>
                <span>
                    Ask question
                    <Reply className="ml-1.5 size-4" />
                </span>
            </Button>
        </a>
    );
};
