import { useCallback, useRef, useState } from "react";

export function useIntersection<T extends HTMLElement>(
    options: IntersectionObserverInit = {},
) {
    const { rootMargin, root, threshold } = options;
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    const ref = useCallback(
        (element: T | null) => {
            if (observer.current) {
                observer.current.disconnect();
                observer.current = null;
            }

            if (element === null) {
                setEntry(null);
                return;
            }

            observer.current = new IntersectionObserver(
                ([_entry]) => {
                    setEntry(_entry);
                },
                { rootMargin, root, threshold },
            );

            observer.current.observe(element);
        },
        [rootMargin, root, threshold],
    );

    return { ref, entry };
}
