import { useState } from "react";

import { mapDelete, mapMerge, mapSet } from "@/utils/es6-map";

export const useMap = <Tv,>(
    keyFn: (item: Tv) => string,
    iterable?: Iterable<Tv> | null,
) => {
    const [state, setState] = useState(
        new Map(
            iterable
                ? Array.from(iterable).map((item) => [keyFn(item), item])
                : undefined,
        ),
    );

    return [
        state,
        {
            add(item: Tv) {
                setState((map) => mapSet(map, keyFn(item), item));
            },
            addAll(items: Tv[]) {
                const newItems = new Map(
                    items.map((item) => [keyFn(item), item]),
                );
                setState((map) => mapMerge(map, newItems, (a, b) => a ?? b));
            },
            remove(item: Tv) {
                setState((map) => mapDelete(map, keyFn(item)));
            },
            removeKey(key: string) {
                setState((map) => mapDelete(map, key));
            },
            toggle(item: Tv) {
                const key = keyFn(item);
                setState((map) =>
                    map.has(key) ? mapDelete(map, key) : mapSet(map, key, item),
                );
            },
            has(item: Tv) {
                return state.has(keyFn(item));
            },
            clear() {
                setState(new Map());
            },
        },
    ] as const;
};
