import { useCallback } from "react";
import { createPortal } from "react-dom";

import { ContextMenu } from "@/components/action-panel/context-menu";
import { PopoverPosition } from "@/components/popover-position";
import { UseContextMenu } from "@/hooks/use-context-menu";

type Props = {
    contextMenu?: UseContextMenu;
};

export const ContextMenuContainer = ({ contextMenu }: Props) => {
    const handleAction = useCallback(() => {
        contextMenu?.deselect();
    }, [contextMenu]);

    if (contextMenu === undefined) return null;

    const selection = {
        text: contextMenu.selection,
        actionContext: contextMenu.actionContext,
    };

    return createPortal(
        <PopoverPosition anchor={contextMenu.anchor}>
            <ContextMenu selection={selection} onAction={handleAction} />
        </PopoverPosition>,
        document.body,
    );
};
