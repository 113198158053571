import { useContext } from "react";
import { useStore } from "zustand";

import { CitatonContext } from "@/context/citation-context";
import { CitationsStore } from "@/stores/citations";

export const useCitationContext = <T>(
    selector: (state: ReturnType<CitationsStore["getState"]>) => T,
): T => {
    const store = useContext(CitatonContext);
    if (!store) {
        throw new Error(
            "Missing CitationContextProvider in the component tree",
        );
    }
    return useStore(store, selector);
};
