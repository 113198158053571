import { Navigate, createBrowserRouter } from "react-router-dom";

import { AuthLevel } from "@/api/types";
import { NotFound } from "@/components/not-found";
import { ExploreContainer } from "@/container/explore";
import { HistoryContainer } from "@/container/history";
import { AboutSettings } from "@/container/settings/about";
import { AppearanceSettings } from "@/container/settings/appearance";
import { BillingSettings } from "@/container/settings/billing";
import { DevSettings } from "@/container/settings/dev";
import { ManageUsersSettings } from "@/container/settings/manage-users";
import { NuxSettings } from "@/container/settings/nux";
import { PasswordSettings } from "@/container/settings/password";
import { ProfileSettings } from "@/container/settings/profile";
import { ThreadConfigSettings } from "@/container/settings/thread-config";
import { ManageUserContextProvider } from "@/context/manage-user-context";
import { AuthGate } from "@/gates/auth-gate";
import { Paywall } from "@/gates/paywall";
import { AdminLayout } from "@/layouts/admin-layout";
import { AppLayout } from "@/layouts/app-layout";
import { AuthLayout } from "@/layouts/auth-layout";
import { LoginLayout } from "@/layouts/login-layout";
import { Payment } from "@/layouts/payment";
import { SettingsLayout } from "@/layouts/settings";
import { AnalyzeRoute } from "@/routes/analyze";
import { ForgotPasswordRoute } from "@/routes/auth/forgot-password";
import { LoginRoute } from "@/routes/auth/login";
import { ResetPasswordRoute } from "@/routes/auth/reset-password";
import { SignupRoute } from "@/routes/auth/signup";
import { ErrorRoute } from "@/routes/error";
import { ExploreRoute } from "@/routes/explore";
import { EmptyGridView } from "@/routes/new-analyze";
import { NotFoundRoute } from "@/routes/not-found";
import { canSeeBillingSettings, canSeeManageUsersSettings } from "@/utils/user";

const loggedOutRoutes = [
    {
        element: <LoginLayout />,
        children: [{ path: "login", Component: LoginRoute }],
    },
    {
        element: <AuthLayout />,
        children: [
            { path: "forgot-password", Component: ForgotPasswordRoute },
            { path: "reset-password", Component: ResetPasswordRoute },
            { path: "signup", Component: SignupRoute },
        ],
    },
];

const superAdminRoutes = [
    {
        path: "admin",
        element: (
            <AuthGate min={AuthLevel.superadmin} fallback={<NotFound />}>
                <AdminLayout />
            </AuthGate>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="dashboard" replace />,
            },
            {
                path: "dashboard",
                lazy: async () => await import("@/routes/admin/dashboard"),
            },
            {
                path: "chat-messages",
                lazy: async () => await import("@/routes/admin/chat-messages"),
            },
            {
                path: "markdown",
                lazy: async () => await import("@/routes/admin/markdown"),
            },
            {
                path: "accounts",
                children: [
                    {
                        index: true,
                        lazy: async () =>
                            await import("@/routes/admin/accounts"),
                    },
                    {
                        path: "new",
                        lazy: async () =>
                            await import("@/routes/admin/create-account"),
                    },
                    {
                        path: ":accountID",
                        lazy: async () =>
                            await import("@/routes/admin/manage-account"),
                    },
                ],
            },
        ],
    },
];

const settingsRoutes = [
    {
        path: "settings",
        element: <SettingsLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/settings/profile" replace />,
            },
            {
                path: "profile",
                Component: ProfileSettings,
            },
            {
                path: "password",
                Component: PasswordSettings,
            },
            {
                path: "appearance",
                Component: AppearanceSettings,
            },
            {
                path: "about",
                Component: AboutSettings,
            },
            {
                path: "billing",
                element: (
                    <AuthGate
                        predicate={canSeeBillingSettings}
                        fallback={
                            <NotFound className="col-span-2 lg:col-span-3" />
                        }
                    >
                        <BillingSettings />
                    </AuthGate>
                ),
            },
            {
                element: (
                    <AuthGate
                        predicate={canSeeManageUsersSettings}
                        fallback={
                            <NotFound className="col-span-2 lg:col-span-3" />
                        }
                    />
                ),
                children: [
                    {
                        path: "manage-users",
                        element: (
                            <ManageUserContextProvider>
                                <ManageUsersSettings />
                            </ManageUserContextProvider>
                        ),
                    },
                ],
            },
            {
                element: (
                    <AuthGate
                        min={AuthLevel.superadmin}
                        fallback={
                            <NotFound className="col-span-2 lg:col-span-3" />
                        }
                    />
                ),
                children: [
                    {
                        path: "dev",
                        Component: DevSettings,
                    },
                    {
                        path: "thread-config",
                        Component: ThreadConfigSettings,
                    },
                    {
                        path: "nux",
                        Component: NuxSettings,
                    },
                ],
            },
        ],
    },
];

export const router = createBrowserRouter([
    {
        path: "/",
        errorElement: <ErrorRoute />,
        children: [
            { index: true, element: <Navigate to="/dashboard" replace /> },
            {
                element: (
                    <AuthGate
                        max={AuthLevel.logged_out}
                        fallback={<Navigate to="/" replace />}
                    />
                ),
                children: loggedOutRoutes,
            },
            {
                element: (
                    <AuthGate
                        min={AuthLevel.authenticated}
                        fallback={<Navigate to="/login" replace />}
                    >
                        <Payment />
                    </AuthGate>
                ),
                children: [
                    {
                        path: "payment",
                        lazy: async () => await import("@/routes/payment"),
                    },
                ],
            },
            {
                element: (
                    <AuthGate
                        min={AuthLevel.authenticated}
                        fallback={<Navigate to="/login" replace />}
                    >
                        <Paywall
                            fallback={<Navigate to="/payment" replace />}
                        />
                    </AuthGate>
                ),
                children: [
                    {
                        element: <AppLayout />,
                        children: [
                            {
                                path: "dashboard",
                                Component: HistoryContainer,
                            },
                            ...settingsRoutes,
                            ...superAdminRoutes,
                        ],
                    },
                    {
                        path: "analyze",
                        children: [
                            { index: true, Component: EmptyGridView },
                            { path: ":id", Component: AnalyzeRoute },
                        ],
                    },
                    {
                        path: "explore",
                        element: (
                            <AuthGate
                                min={AuthLevel.superadmin}
                                fallback={<NotFoundRoute />}
                            />
                        ),
                        children: [
                            { index: true, Component: ExploreContainer },
                            { path: ":id", Component: ExploreRoute },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <NotFoundRoute />,
    },
]);
