import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const CitationNumber = forwardRef(
    (
        { className, ...props }: HTMLAttributes<HTMLSpanElement>,
        ref: ForwardedRef<HTMLSpanElement>,
    ) => (
        <span
            ref={ref}
            className={cn(
                "mx-1 select-none rounded bg-neutral-300 px-2 font-headline font-extrabold transition-colors dark:bg-neutral-700",
                className,
            )}
            {...props}
        />
    ),
);
