import { PropsWithChildren, createContext, useState } from "react";

import { Subscription } from "@/api/types";
import { SubscriptionContainer } from "@/container/paywall/subscription";
import { emptyFunction } from "@/utils/empty-function";

type TStripeContext = {
    subscription: Subscription;
    clientSecret: string | null;
    setClientSecret: (clientSecret: string) => void;
};

export const StripeContext = createContext<TStripeContext>({
    subscription: {} as Subscription,
    clientSecret: null,
    setClientSecret: emptyFunction,
});

export const StripeContextProvider = (props: PropsWithChildren) => {
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    return (
        <SubscriptionContainer>
            {({ subscription }) => (
                <StripeContext.Provider
                    value={{ clientSecret, setClientSecret, subscription }}
                    {...props}
                />
            )}
        </SubscriptionContainer>
    );
};
