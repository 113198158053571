import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
} from "react";
import useSWRImmutable from "swr/immutable";
import { useStore } from "zustand";

import { ColumnDefinition, FullDocumentCollection, UUID } from "@/api/types";
import {
    FindingGroupEventBody,
    IncomingWebsocketEventType,
} from "@/api/ws/websocket-types";
import { useApi } from "@/hooks/use-api";
import { useOnUnmount } from "@/hooks/use-on-unmount";
import { useWebsocket } from "@/hooks/use-websocket";
import {
    createGridViewStore,
    GridViewStore,
    VersionedState,
} from "@/stores/grid-view";

export const GridViewContext = createContext<GridViewStore | null>(null);

const ProdviderImpl = ({
    report,
    persistedClientState,
    availableColumns,
    ...props
}: PropsWithChildren<{
    report: FullDocumentCollection;
    persistedClientState: VersionedState<Record<string, unknown>> | null;
    availableColumns: ColumnDefinition[];
}>) => {
    const api = useApi();
    const store = useRef<GridViewStore>();
    if (!store.current) {
        store.current = createGridViewStore({
            api,
            report,
            persistedClientState,
            availableColumns,
        });
    }

    // update report on SWR revalidate
    const updateReport = useStore(store.current, (s) => s.updateReport);
    useEffect(() => {
        updateReport(report);
    }, [report]);

    // update document status and finding groups from WS
    const updateDocumentStatus = useStore(
        store.current,
        (s) => s.updateDocumentStatus,
    );
    const addFindingGroup = useStore(store.current, (s) => s.addFindingGroup);
    const updateFindingGroup = useStore(
        store.current,
        (s) => s.updateFindingGroup,
    );
    const wsFilter = useCallback(
        ({ message_id }: FindingGroupEventBody): boolean =>
            message_id === report.id,
        [report.id],
    );
    useWebsocket((ws) => {
        ws.on(
            IncomingWebsocketEventType.document_status_updated,
            ({ document_id, status }) =>
                updateDocumentStatus(document_id, status),
        );
        ws.onIf(
            IncomingWebsocketEventType.finding_group_created,
            wsFilter,
            ({ document_id, ...finding_group }) =>
                addFindingGroup(document_id, finding_group),
        );
        ws.on(
            IncomingWebsocketEventType.finding_group_updated,
            ({ document_id, ...finding_group }) =>
                updateFindingGroup(document_id, finding_group),
        );
    });

    return <GridViewContext.Provider value={store.current} {...props} />;
};

export const GridViewContextProvider = ({
    messageID,
    ...props
}: PropsWithChildren<{ messageID: UUID }>) => {
    const api = useApi();
    const { data: asyncProps, mutate } = useSWRImmutable(
        [messageID, "grid_view"],
        async ([id]) => ({
            report: await api.fetch_report(id),
            persistedClientState: await api.fetch_grid_view_state(id),
        }),
        { suspense: true },
    );
    const { data: availableColumns } = useSWRImmutable(
        "report/column-definition",
        async () => await api.fetch_available_columns(),
        { suspense: true },
    );

    // clear cache to force re-retch on mount without rendering stale data
    useOnUnmount(() => mutate(undefined, { revalidate: false }));

    return (
        <ProdviderImpl
            key={messageID}
            availableColumns={availableColumns}
            {...asyncProps}
            {...props}
        />
    );
};
