import { Table as TanstackTable, Column } from "@tanstack/react-table";
import { ListFilter } from "lucide-react";
import { HTMLAttributes } from "react";

import { DocumentType } from "@/api/types";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Headline } from "@/components/headline";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { StaticColumn } from "@/conf/grid-view";
import { cn } from "@/lib/utils";
import { setToggle } from "@/utils/es6-set";
import { getDocumentTypeLabel } from "@/utils/report";
import { notEmptyOrNull } from "@/utils/string-helpers";

const ColumnFilter = <T,>({
    title,
    column,
    labelFmt,
    className,
    ...props
}: {
    title: string;
    column: Column<DocumentWithAsyncFindings, T>;
    labelFmt?: (val: T) => string;
} & HTMLAttributes<HTMLDivElement>) => {
    const values = column.getFacetedUniqueValues();
    const filter = column.getFilterValue() as Set<string> | undefined;
    return (
        <div className={cn("space-y-2", className)} {...props}>
            <Headline level={4} highlighted>
                {title}
            </Headline>
            {Array.from(values.entries())
                .filter(([value]) => notEmptyOrNull(value))
                .map(([value, count]) => (
                    <label
                        key={value}
                        className="flex items-center justify-between gap-2 rounded-md border px-2 py-1 transition-colors hover:bg-muted/20"
                        role="button"
                    >
                        <Checkbox
                            checked={filter !== undefined && filter.has(value)}
                            onCheckedChange={(isChecked) => {
                                column.setFilterValue(
                                    (val: Set<string> | undefined) =>
                                        setToggle(
                                            val ?? new Set(),
                                            value,
                                            isChecked === true,
                                        ),
                                );
                            }}
                        />
                        <span className="grow">
                            {labelFmt ? labelFmt(value) : value}
                        </span>
                        <Badge variant="secondary" size="sm">
                            {count}
                        </Badge>
                    </label>
                ))}
        </div>
    );
};

export const Filter = ({
    table,
}: {
    table: TanstackTable<DocumentWithAsyncFindings>;
}) => {
    const tickerColumn = table.getColumn(StaticColumn.ticker);
    const docTypeColumn = table.getColumn(StaticColumn.doc_type);
    const tickerFilter = tickerColumn?.getFilterValue() as
        | Set<string>
        | undefined;
    const docTypeFilter = docTypeColumn?.getFilterValue() as
        | Set<string>
        | undefined;
    const hasFilter =
        (tickerFilter?.size ?? 0) + (docTypeFilter?.size ?? 0) > 0;

    return (
        <Popover>
            <PopoverTrigger asChild>
                <ButtonWithTooltip
                    tooltip="Filter"
                    variant={hasFilter ? "primary" : "ghost"}
                    size="icon-sm"
                >
                    <ListFilter className="size-4" />
                </ButtonWithTooltip>
            </PopoverTrigger>
            <PopoverContent className="mx-2 grid w-[600px] grid-cols-2 divide-x divide-border p-0 text-sm">
                {tickerColumn && (
                    <ColumnFilter
                        className="p-4"
                        title="Ticker"
                        column={tickerColumn}
                    />
                )}
                {docTypeColumn && (
                    <ColumnFilter
                        className="p-4"
                        title="Document Types"
                        column={
                            docTypeColumn as Column<
                                DocumentWithAsyncFindings,
                                DocumentType
                            >
                        }
                        labelFmt={getDocumentTypeLabel}
                    />
                )}
                <div className="col-span-full flex justify-end border-t bg-muted">
                    <Button
                        variant="link"
                        className=""
                        onClick={() => {
                            tickerColumn?.setFilterValue(undefined);
                            docTypeColumn?.setFilterValue(undefined);
                        }}
                    >
                        clear
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
};
