import { ForwardedRef, forwardRef, ReactNode } from "react";

import { HoverTooltip } from "@/components/hover-tooltip";
import { Button, ButtonProps } from "@/components/ui/button";

export const ButtonWithTooltip = forwardRef(
    (
        { tooltip, children, ...props }: ButtonProps & { tooltip: ReactNode },
        ref: ForwardedRef<HTMLButtonElement>,
    ) => {
        return (
            <HoverTooltip tooltip={tooltip}>
                <Button ref={ref} {...props}>
                    {children}
                    <span className="sr-only">{tooltip}</span>
                </Button>
            </HoverTooltip>
        );
    },
);
