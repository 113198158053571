import { useContext, useEffect } from "react";

import { SubscriptionClient } from "@/api/ws/subscription-client";
import { AppContext } from "@/context/app-context";

type Destructor = () => void;

export const useWebsocket = (
    setupHandler: (sock: SubscriptionClient) => void | Destructor,
    deps?: ReadonlyArray<unknown>,
) => {
    const { websocket } = useContext(AppContext);
    useEffect(() => {
        if (!websocket) {
            return;
        }

        const subscription_client = new SubscriptionClient(websocket);
        const destructor = setupHandler(subscription_client);
        return () => {
            if (destructor) {
                destructor();
            }
            subscription_client.disconnect();
        };
    }, [websocket, ...(deps || [])]);
};
