import { useContext } from "react";
import { useStore } from "zustand";

import { GridViewContext } from "@/context/grid-view-context";
import { GridViewStore } from "@/stores/grid-view";

export const useGridView = <T>(
    selector: (state: ReturnType<GridViewStore["getState"]>) => T,
): T => {
    const store = useContext(GridViewContext);
    if (!store) {
        throw new Error(
            "Missing GridViewContextProvider in the component tree",
        );
    }
    return useStore(store, selector);
};
