import { useContext } from "react";

import { User } from "@/api/types";
import { AppContext } from "@/context/app-context";

type Opts = {
    nullable: boolean;
};

function assert<T>(val: T | null): T {
    if (val == null) {
        throw new Error("Not logged in");
    }
    return val;
}

type Ret<T extends Opts> = T["nullable"] extends true ? User | null : User;

export const useUser = <T extends Opts>(
    opts: T = { nullable: true } as T,
): Ret<T> => {
    const { user } = useContext(AppContext);
    if (opts.nullable) {
        return user as Ret<T>;
    }
    return assert(user);
};
