const avgReducer = (avg: number, val: number, _: unknown, list: number[]) =>
    avg + val / list.length;

export const avg = (values: number[]): number =>
    values.length ? values.reduce(avgReducer, 0) : 0;

export const add = (a: number, b: number): number => a + b;

export const clamp = (value: number, min: number, max: number) => {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    } else {
        return value;
    }
};
