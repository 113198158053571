import {
    createContext,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useStore } from "zustand";

import { APActionCitation, DocumentInfo } from "@/api/types";
import { CitationsStore, createCitationsStore } from "@/stores/citations";
import { toTuple } from "@/utils/entity";

export const CitatonContext = createContext<CitationsStore | null>(null);

type Props = {
    citations?: APActionCitation[];
    documents?: DocumentInfo[];
};

export const CitationContextProvider = ({
    citations,
    documents,
    children,
}: PropsWithChildren<Props>) => {
    const store = useRef<CitationsStore>();

    const citationMap = useMemo(
        () => new Map(citations?.map(toTuple)),
        [citations],
    );
    const documentMap = useMemo(
        () => new Map(documents?.map(toTuple)),
        [documents],
    );

    if (!store.current) {
        store.current = createCitationsStore({
            citations: citationMap,
            documents: documentMap,
        });
    }

    const updateCitations = useStore(store.current, (s) => s.updateCitations);
    useEffect(() => {
        updateCitations(citationMap);
    }, [updateCitations, citationMap]);

    const updateDocuments = useStore(store.current, (s) => s.updateDocuments);
    useEffect(() => {
        updateDocuments(documentMap);
    }, [updateDocuments, documentMap]);

    return (
        <CitatonContext.Provider value={store.current}>
            {children}
        </CitatonContext.Provider>
    );
};
