import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";

type Deserialize<T> = (val: string | null) => T;
type Serialize<T> = (val: T) => string;
type Config<T> = {
    key: string;
    serialize: Serialize<T>;
    deserialize: Deserialize<T>;
};

const useRawLocalStorage = (key: string) => {
    const [value, setValueInternal] = useState<string | null>(
        window.localStorage.getItem(key),
    );

    const listener = useCallback(() => {
        setValueInternal(window.localStorage.getItem(key));
    }, [key, setValueInternal]);

    useEffect(() => {
        window.addEventListener("storage", listener);
        return () => window.removeEventListener("storage", listener);
    }, [listener]);

    const setValue: Dispatch<SetStateAction<string | null>> = useCallback(
        (v) => {
            const val = v instanceof Function ? v(value) : v;
            if (val === null) {
                window.localStorage.removeItem(key);
            } else {
                window.localStorage.setItem(key, val);
            }
            window.dispatchEvent(new Event("storage"));
        },
        [key, value],
    );

    return [value, setValue] as const;
};

export const useLocalStorage = <T>({
    key,
    serialize,
    deserialize,
}: Config<T>) => {
    const [storage, setStorage] = useRawLocalStorage(key);
    const [value, setValueInternal] = useState<T>(deserialize(storage));

    useEffect(() => {
        setValueInternal(deserialize(storage));
    }, [storage, setValueInternal, deserialize]);

    const setValue: Dispatch<SetStateAction<T>> = useCallback(
        (v) => {
            const val = v instanceof Function ? v(value) : v;
            setStorage(serialize(val));
        },
        [value, setStorage, serialize],
    );

    return [value, setValue] as const;
};
