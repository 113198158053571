import { Component, ReactNode } from "react";

type ErrorBoundaryProps = {
    fallback?: ReactNode | ((error: Error) => ReactNode);
    children: ReactNode;
};

type State = {
    error: Error | null;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
    state = {
        error: null,
    };

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    render() {
        if (this.state.error !== null) {
            return typeof this.props.fallback === "function"
                ? this.props.fallback(this.state.error)
                : this.props.fallback;
        }
        return this.props.children;
    }
}
