import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";
import { useStore } from "zustand";

import { UUID } from "@/api/types";
import {
    EventErrorBody,
    IncomingWebsocketEventType,
} from "@/api/ws/websocket-types";
import { AppContext } from "@/context/app-context";
import { useApi } from "@/hooks/use-api";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useWebsocket } from "@/hooks/use-websocket";
import { ActionsStore, createActionsStore } from "@/stores/actions";

export const ActionsContext = createContext<ActionsStore | null>(null);

type Props = {
    messageID: UUID;
    actionPanelOpen?: boolean;
};

export const ActionsContextProvider = ({
    messageID,
    actionPanelOpen,
    ...props
}: PropsWithChildren<Props>) => {
    const [devMode] = useDevMode();
    const api = useApi();
    const { websocket } = useContext(AppContext);
    const store = useRef<ActionsStore>();

    const conversationID = useSWRImmutable(
        [messageID, "conversation_id"],
        async ([id]) => await api.fetch_conversation_id(id),
        { suspense: true },
    );

    if (!store.current) {
        store.current = createActionsStore({
            api,
            websocket,
            messageID,
            isActionPanelOpen: actionPanelOpen,
            conversationID: conversationID.data,
            mutateConversationID: conversationID.mutate,
        });
    }

    const actionAck = useStore(store.current, (s) => s.actionAck);
    const actionChunk = useStore(store.current, (s) => s.actionChunk);
    const actionStatusUpdate = useStore(
        store.current,
        (s) => s.actionStatusUpdate,
    );
    const actionStreamComplete = useStore(
        store.current,
        (s) => s.actionStreamComplete,
    );
    const actionSuccess = useStore(store.current, (s) => s.actionSuccess);
    const actionUpdate = useStore(store.current, (s) => s.actionUpdate);
    const actionError = useStore(store.current, (s) => s.actionError);

    const removeAction = useStore(store.current, (s) => s.removeAction);
    const handleEventError = useCallback(
        (body: EventErrorBody) => {
            removeAction(body.id);
            if (devMode) {
                toast.error(`Event Error: ${body.reason}`);
            } else {
                toast.error("Someting went wrong. Please try again.");
            }
        },
        [devMode, removeAction],
    );

    useWebsocket(
        (ws) => {
            ws.onIf(
                IncomingWebsocketEventType.action_ack,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionAck,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_chunk,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionChunk,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_status_update,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionStatusUpdate,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_success,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionSuccess,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_update,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionUpdate,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_stream_complete,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionStreamComplete,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_error,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionError,
            );
            ws.onIf(
                IncomingWebsocketEventType.event_error,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                handleEventError,
            );
        },
        [messageID, conversationID.data],
    );

    const setWebsocket = useStore(store.current, (s) => s.setWebsocket);
    useEffect(() => {
        setWebsocket(websocket);
    }, [setWebsocket, websocket]);

    const isLoading = useStore(store.current, (s) => s.isLoading);
    const pageInfo = useStore(store.current, (s) => s.pageInfo);
    const fetchMoreActions = useStore(store.current, (s) => s.fetchMoreActions);
    useEffect(() => {
        if (!isLoading && pageInfo == undefined) {
            fetchMoreActions();
        }
    }, [isLoading, pageInfo, fetchMoreActions]);

    return <ActionsContext.Provider value={store.current} {...props} />;
};
