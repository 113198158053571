import {
    Column,
    ColumnType,
    FindingGroupInfo,
    FindingGroupType,
    UUID,
} from "@/api/types";

export const getColumId = (c: Column): string => {
    switch (c.column_type) {
        case ColumnType.system:
            return c.finding_group_type;
        case ColumnType.user_defined:
            return c.details.id;
    }
};

export const getColumnIdFromFindingGroup = (
    fg: FindingGroupInfo,
): string | undefined => {
    switch (fg.type) {
        case FindingGroupType.user_defined_text:
            return "probe" in fg ? fg.probe?.id : undefined;
        default:
            return fg.type;
    }
};

export const getProbeID = (c: Column): UUID | undefined => {
    switch (c.column_type) {
        case ColumnType.system:
            return undefined;
        case ColumnType.user_defined:
            return c.details.id;
    }
};
