import {
    APAction,
    APActionChunk,
    APActionBase,
    UUID,
    FindingGroupInfo,
    APActionStatusUpdate,
    DocumentStatus,
} from "@/api/types";

export enum IncomingWebsocketEventType {
    auth_success = "auth_success",
    error = "error",
    subscribe_success = "subscribe_success",
    unsubscribe_success = "unsubscribe_success",
    upload_status_changed = "upload_status_changed",
    context_load_success = "context_load_success",
    context_load_error = "context_load_error",
    context_update_ack = "context_update_ack",
    context_update_success = "context_update_success",
    context_update_error = "context_update_error",
    action_ack = "action_ack",
    action_chunk = "action_chunk",
    action_update = "action_update",
    action_success = "action_success",
    action_error = "action_error",
    action_stream_complete = "action_stream_complete",
    action_status_update = "action_status_update",
    event_error = "event_error",
    finding_group_created = "finding_group_created",
    finding_group_updated = "finding_group_updated",
    message_title_changed = "message_title_changed",
    document_status_updated = "document_status_updated",
}

enum DocumentUploadStatus {
    initial = "initial",
    processing = "processing",
    ready = "ready",
    failed = "failed",
}

export enum ContextUpdateMethod {
    add = "add",
    remove = "remove",
}

/** EVENT BODY DEFINTIONS */

export interface AuthAckEventBody {
    user_id: UUID;
}

export interface ErrorEventBody {
    error: string;
    reason?: string;
}

export interface SubscriptionUpdateEventBody {
    channels: string[];
    remaining_subscriptions: string[];
}

export interface ContextLoadSuccessEventBody {
    message_id: UUID;
    document_ids: UUID[];
}

export interface ContextLoadErrorEventBody {
    message_id: UUID;
}

export interface FileUploadStatusChangedEventBody {
    upload_id: UUID;
    status: DocumentUploadStatus;
}

export interface EventErrorBody {
    id: UUID;
    conversation_id: UUID;
    message_id: UUID;
    reason: string;
}

export interface ContextUpdateEventBody {
    message_id: UUID;
    method: ContextUpdateMethod;
    document_ids: UUID[];
}

export interface ContextUpdateErrorEventBody extends ContextUpdateEventBody {
    reason: string;
}

export interface FindingGroupEventBody extends FindingGroupInfo {
    message_id: UUID;
    document_id: UUID;
}

export interface MessageTitleChangedEventBody {
    message_id: UUID;
    response_to: UUID | null;
    title: string;
}

export interface InboundWebsocketEvents {
    [IncomingWebsocketEventType.auth_success]: AuthAckEventBody;
    [IncomingWebsocketEventType.error]: ErrorEventBody;
    [IncomingWebsocketEventType.subscribe_success]: SubscriptionUpdateEventBody;
    [IncomingWebsocketEventType.unsubscribe_success]: SubscriptionUpdateEventBody;
    [IncomingWebsocketEventType.upload_status_changed]: FileUploadStatusChangedEventBody;
    [IncomingWebsocketEventType.context_load_success]: ContextLoadSuccessEventBody;
    [IncomingWebsocketEventType.context_load_error]: ContextLoadErrorEventBody;
    [IncomingWebsocketEventType.context_update_ack]: ContextUpdateEventBody;
    [IncomingWebsocketEventType.context_update_success]: ContextUpdateEventBody;
    [IncomingWebsocketEventType.context_update_error]: ContextUpdateErrorEventBody;
    [IncomingWebsocketEventType.action_ack]: APActionBase;
    [IncomingWebsocketEventType.action_chunk]: APActionChunk;
    [IncomingWebsocketEventType.action_stream_complete]: APAction;
    [IncomingWebsocketEventType.action_status_update]: APActionStatusUpdate;
    [IncomingWebsocketEventType.action_update]: APAction;
    [IncomingWebsocketEventType.action_success]: APAction;
    [IncomingWebsocketEventType.action_error]: APActionBase;
    [IncomingWebsocketEventType.event_error]: EventErrorBody;
    [IncomingWebsocketEventType.finding_group_created]: FindingGroupEventBody;
    [IncomingWebsocketEventType.finding_group_updated]: FindingGroupEventBody;
    [IncomingWebsocketEventType.message_title_changed]: MessageTitleChangedEventBody;
    [IncomingWebsocketEventType.document_status_updated]: {
        document_id: UUID;
        status: DocumentStatus;
    };
}

export interface WebsocketInboundMessage<
    Tk extends keyof InboundWebsocketEvents,
> {
    event_type: Tk;
    body: InboundWebsocketEvents[Tk];
}
