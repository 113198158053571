import { FilePlus2 } from "lucide-react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { ContextItem, ContextType, FileStatus } from "@/api/types";
import { DocumentSearch } from "@/components/analyze/document-search";
import { Dropzone } from "@/components/analyze/dropzone";
import { UploadToast } from "@/components/analyze/upload-toast";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { Headline } from "@/components/headline";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { MAX_DOCUMENT_COUNT } from "@/conf/report";
import { FileUploadContextProvider } from "@/context/file-upload-context";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { usePageTitle } from "@/hooks/use-page-title";
import { AppLayout } from "@/layouts/app-layout";
import { encodeContextItem } from "@/utils/context-items";
import { isFileUploaded } from "@/utils/file-upload";

const Impl = () => {
    const api = useApi();
    const navigate = useNavigate();
    const { open } = useFileUploadContext((s) => s.dropzone);
    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    const createAction = useAsyncState(
        async (items: ContextItem[]) =>
            await api.create_report(items.map(encodeContextItem)),
        {
            onSuccess: (report) => navigate(`/analyze/${report.id}`),
            onError: () =>
                toast.error("Something went wrong, please try again"),
        },
    );

    useEffect(() => {
        const uploads = Array.from(files.values());
        if (
            uploads.length > 0 &&
            uploads.every((f) => f.status >= FileStatus.uploaded)
        ) {
            createAction.submit(
                uploads.filter(isFileUploaded).map((f) => ({
                    type: ContextType.EXISTING_DOCUMENT,
                    document_id: f.document_info.id,
                })),
            );
        }
    }, [files]);

    return (
        <AppLayout className="relative flex h-screen grow flex-col overflow-scroll bg-page-background">
            <div className="flex min-h-14 items-center justify-between gap-8 border-b border-foreground/5 bg-foreground px-4 py-2 dark:bg-neutral-800">
                <Breadcrumb className="text-sm">
                    <BreadcrumbList className="flex-nowrap">
                        <BreadcrumbItem className="shrink-0">
                            <BreadcrumbLink
                                className="underline-offset-4 hover:text-background hover:underline dark:hover:text-foreground"
                                asChild
                            >
                                <NavLink to="/">Home</NavLink>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem className="flex items-center font-semibold text-background dark:text-neutral-400">
                            <span className="line-clamp-1">
                                Analyze Documents
                            </span>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <div className="grow bg-background py-10">
                <div className="container space-y-10 text-center">
                    <Headline level={2} highlighted>
                        Start by Selecting Documents
                    </Headline>
                    <div className="mx-auto flex max-w-screen-lg grow flex-col items-center gap-6 text-left">
                        <DocumentSearch
                            confirmSelection
                            addItems={createAction.submit}
                            disabled={files.size > 0}
                            maxDocumentCount={MAX_DOCUMENT_COUNT}
                            placeholder="Find SEC Filings and Earnings Call Transcripts..."
                            className="w-full bg-background"
                        />
                        <p>or</p>
                        <ButtonWithTooltip
                            variant="outline"
                            className="gap-2 text-muted-foreground"
                            tooltip="Click to add PDFs or use Drag & Drop"
                            onClick={open}
                        >
                            <FilePlus2 className="size-4" />
                            Add PDFs
                        </ButtonWithTooltip>
                    </div>
                </div>
            </div>
            <Dropzone />
            <UploadToast
                files={Array.from(files.values())}
                onDismiss={resetFiles}
            />
        </AppLayout>
    );
};

export const EmptyGridView = () => {
    usePageTitle("Analyze Documents");
    return (
        <FileUploadContextProvider maxFiles={MAX_DOCUMENT_COUNT}>
            <Impl />
        </FileUploadContextProvider>
    );
};
