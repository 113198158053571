import { HttpStatusCode } from "@/api/http-status-codes";
import {
    APActionBase,
    APIContextItem,
    Account,
    AccountSettings,
    AccountType,
    BillingAddress,
    BillingDetails,
    Company,
    ContractInterval,
    Currency,
    CursorPage,
    CustomerStatus,
    EncodedContextItem,
    FullDocumentCollection,
    InviteCode,
    InviteCodeStatus,
    InviteInfo,
    Invoice,
    BaseDocumentCollection,
    PaymentMethod,
    Product,
    Sorting,
    Subscription,
    ThreadConfig,
    UUID,
    User,
    UserInvite,
    UserType,
    UserUploadContextItem,
    APActionType,
    APActionStatus,
    CompanySearchResult,
    DocumentSearchResult,
    ExistingDocumentContextItem,
    TimeframeType,
    DocumentCollection,
    DocumentFindingGroup,
    ProbeType,
    ColumnDefinition,
    FindingGroupType,
    FileUploadResult,
    Column,
    ColumnType,
    FindingGroupInfo,
    VersionDetails,
    FullDecoratedDocument,
    Probe,
} from "@/api/types";
import { base_api_url } from "@/api/utils";
import { VersionedState } from "@/stores/grid-view";

enum HTTPMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    OPTIONS = "OPTIONS",
}

export type CreateMessageResponseType = {
    thread_id: UUID;
    message_id: UUID;
};

export type UserInfo = {
    first_name: string;
    last_name: string;
};

export type SubscriptionConfiguration = {
    base_price: number;
    currency: Currency;
    contract_interval_count: number;
};

export type SubscriptionInfo = {
    product_id?: UUID;
    send_invoices: boolean;
    contract_interval: ContractInterval | null;
    trial_days?: number | undefined;
    price_id?: UUID | undefined;
    custom_configuration?: SubscriptionConfiguration | undefined;
};

export type CreateAccountData = {
    account_name?: string | undefined;
    account_type: AccountType;
    username: string;
    first_name?: string | undefined;
    last_name?: string | undefined;
    subscription_info?: SubscriptionInfo | undefined;
};

export type CreateUserData = {
    user_type: UserType;
    username: string;
    first_name: string | null;
    last_name: string | null;
};

export interface InviteAcceptance extends InviteInfo {
    password: string;
}

export type CursorPaginationParams = {
    size: number;
    cursor?: string | null;
};

export type PagePaginationParams = {
    size: number;
    page?: number;
};

export type PaginationParams = CursorPaginationParams | PagePaginationParams;

export enum FeedbackObjectType {
    SYNTHESIS_FINDING = "synthesis_finding",
    ANALYSIS_FINDING = "analysis_finding",
    DOCUMENT_REFERENCE = "document_reference",
    SYNTHESIS = "synthesis",
    ANALYSIS = "analysis",
    ACTION = "action",
}

export type FeedbackData = {
    object_id: UUID;
    object_type: FeedbackObjectType;
    object_subtype?: string | null;
    positive: boolean;
};

export type BillingDetailsData = {
    billing_email: string;
    billing_name: string;
    billing_address: BillingAddress;
};

export type ConfirmIntentPayload = {
    action_id: UUID;
    tickers: string[];
    document_types: (
        | "earnings_transcript"
        | "sec_10k"
        | "sec_10q"
        | "sec_8k"
    )[];
    timeframe_type: TimeframeType;
    start_date: string;
    end_date: string;
};

export interface ProbeBase {
    name: string;
    prompt: string;
    type: ProbeType;
}

export type AddColumnPayload =
    | {
          column_type: ColumnType.user_defined;
          details: ProbeBase;
      }
    | {
          column_type: ColumnType.user_defined;
          id: UUID;
          schema_id: UUID;
          details: ProbeBase;
      }
    | { column_type: ColumnType.system; finding_group_type: FindingGroupType };

export interface UpdateProbePayload extends ProbeBase {
    schema_id: UUID;
}

export type RemoveColumnPayload =
    | { column_type: ColumnType.user_defined; id: UUID }
    | { column_type: ColumnType.system; finding_group_type: FindingGroupType };

const getPaginationParams = (
    pagination?: PaginationParams,
    defaultPageSize: number = 10,
): Record<string, string> | null => {
    if (!pagination) {
        return { size: String(defaultPageSize) };
    }
    return {
        size: String(pagination.size),
        ...("cursor" in pagination && pagination.cursor != null
            ? { cursor: pagination.cursor }
            : null),
        ...("page" in pagination && pagination.page != null
            ? { page: String(pagination.page) }
            : null),
    };
};

type TUrlParams<T> = Record<string, T> | [string, T][];

const expandParams = <T>(key: string, value: T | T[]): [string, T][] =>
    Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]];

const paramsToTuple = <T>(params: TUrlParams<T | T[]>): [string, T][] =>
    (Array.isArray(params)
        ? params
        : Array.from(Object.entries(params))
    ).flatMap(([key, value]) => expandParams(key, value));

type RequestOptions = {
    body: unknown;
    omitContentType?: boolean;
    params: TUrlParams<string> | null;
    suppressErrors: boolean; // don't throw
    silent: boolean; // don't invoke callbacks
};

export class HttpError extends Error {
    public name: string = "HttpError";
    public status: HttpStatusCode | undefined;

    public constructor(message: string, status?: HttpStatusCode) {
        super(message);
        this.status = status;
    }
}

export class BrightwaveAPI {
    private token?: string;
    private base_url: string = base_api_url;
    private callbacks: (() => void)[] = [];

    public constructor(token?: string) {
        this.token = token;
    }

    // hook for logging out users then the API returns a 401, indicating
    // that the token is invalid; returns unsubscribe function
    public onNotAuthenticated(callback: () => void): () => void {
        this.callbacks.push(callback);
        return () => {
            this.callbacks = this.callbacks.filter((cb) => cb !== callback);
        };
    }

    private getBody(body: unknown): string | FormData | null {
        if (body == null) {
            return null;
        }
        if (typeof body === "string" || body instanceof FormData) {
            return body;
        }
        return JSON.stringify(body);
    }

    private getURL(
        endpoint: string,
        params: TUrlParams<string> | null | undefined,
    ) {
        if (params == null) {
            return `${this.base_url}/${endpoint}`;
        }
        return `${this.base_url}/${endpoint}?${new URLSearchParams(params)}`;
    }

    private async send_request(
        method: HTTPMethod,
        endpoint: string,
        options?: Partial<RequestOptions> & { authRequired?: boolean },
    ): Promise<Response> {
        const headers: Record<string, string> = options?.omitContentType
            ? {}
            : { "Content-Type": "application/json" };
        if (options?.authRequired) {
            if (!this.token) {
                throw new Error(
                    "Cannot send authenticated request without a token",
                );
            }
            headers["Authorization"] = `Bearer ${this.token}`;
        }

        const res = await fetch(this.getURL(endpoint, options?.params), {
            method: method,
            headers: headers,
            body: this.getBody(options?.body),
        });

        if (options?.silent !== true) {
            if (res.status === 401) {
                for (const cb of this.callbacks) {
                    cb();
                }
            }
        }

        if (options?.suppressErrors !== true && !res.ok) {
            const json = await res.json();
            throw new HttpError(json.detail, res.status);
        }

        return res;
    }

    private async send_authenticated_request(
        method: HTTPMethod,
        endpoint: string,
        options?: Partial<RequestOptions>,
    ): Promise<Response> {
        return this.send_request(method, endpoint, {
            ...options,
            authRequired: true,
        });
    }

    public async authenticated_get(
        endpoint: string,
        options?: Partial<Omit<RequestOptions, "body">>,
    ): Promise<Response> {
        return await this.send_authenticated_request(
            HTTPMethod.GET,
            endpoint,
            options,
        );
    }

    public set_token(token: string) {
        this.token = token;
    }

    public async check_invite_code(
        invite_info: InviteInfo,
    ): Promise<InviteCodeStatus> {
        const response = await this.send_request(
            HTTPMethod.POST,
            "onboard/check-status",
            { body: invite_info },
        );
        const json = (await response.json()) as { status: InviteCodeStatus };
        return json.status;
    }

    public async onboard_accept(
        invite_info: InviteAcceptance,
    ): Promise<string> {
        const response = await this.send_request(
            HTTPMethod.POST,
            "onboard/accept",
            { body: invite_info },
        );
        const json = await response.json();
        return json.access_token;
    }

    public async login_user(
        username: string,
        password: string,
    ): Promise<string> {
        const token_response = await this.send_request(
            HTTPMethod.POST,
            "auth/token",
            { body: { username, password } },
        );
        const json = await token_response.json();
        // TODO: should probably just set the client's token here
        return json.access_token;
    }

    public async logout(): Promise<void> {
        await this.send_authenticated_request(HTTPMethod.POST, "auth/logout", {
            silent: true,
            suppressErrors: true,
        });
    }

    public async forgot_password(username: string): Promise<void> {
        await this.send_request(HTTPMethod.POST, "auth/password/forgot", {
            body: { username },
        });
    }

    public async reset_password(
        nonce: string,
        password: string,
    ): Promise<void> {
        await this.send_request(
            HTTPMethod.POST,
            "auth/password/complete-reset",
            { body: { nonce, password } },
        );
    }

    public async update_password(
        current_password: string,
        new_password: string,
    ): Promise<boolean> {
        await this.send_authenticated_request(
            HTTPMethod.POST,
            "auth/password/change",
            { body: { current_password, new_password } },
        );
        return true;
    }

    public async fetch_user_info(): Promise<User> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "user",
        );
        return response.json();
    }

    public async fetch_analysis_by_id(
        finding_group_id: UUID,
        options?: Partial<{ load_pills: boolean }>,
    ): Promise<DocumentFindingGroup> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `analysis/${finding_group_id}`,
            options?.load_pills ? { params: { load_pills: "1" } } : undefined,
        );
        return await response.json();
    }

    public async fetch_settings_model_options(): Promise<{
        available: string[];
        all: string[];
    }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "settings/thread-config/model-options",
        );
        return await response.json();
    }

    public async fetch_settings_thread_config(): Promise<ThreadConfig> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "settings/thread-config",
        );
        return await response.json();
    }

    public async update_settings_thread_config(
        config: ThreadConfig,
    ): Promise<ThreadConfig> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "settings/thread-config",
            { body: config },
        );
        return await response.json();
    }

    public async update_user_info(user_info: UserInfo): Promise<User> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "user/info",
            { body: user_info },
        );
        return await response.json();
    }

    public async admin_fetch_account_list(
        sorting: Sorting | null,
        active: boolean | null,
        account_types: AccountType[] | null,
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<Account>> {
        const params = [
            ...Object.entries({
                ...getPaginationParams(pagination),
                ...(active != null ? { active: String(active) } : undefined),
                ...sorting,
            }),
            ...(account_types ?? []).map((type): [string, string] => [
                "account_types",
                type,
            ]),
        ];
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/account/list`,
            { params },
        );
        return await response.json();
    }

    public async admin_fetch_account_info(accountID: UUID): Promise<Account> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/account/${accountID}/info`,
        );
        return await response.json();
    }

    public async admin_fetch_account_users(
        accountID: UUID,
        options: Partial<{
            sorting: Sorting | null;
            active: boolean | null;
            pagination?: CursorPaginationParams;
        }> = {},
    ): Promise<CursorPage<User>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/account/${accountID}/users`,
            {
                params: {
                    ...getPaginationParams(options.pagination),
                    ...options.sorting,
                    ...(options.active != null
                        ? { active: String(options.active) }
                        : undefined),
                },
            },
        );
        return await response.json();
    }

    public async admin_create_account(
        invite_info: CreateAccountData,
    ): Promise<UserInvite> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account`,
            { body: invite_info },
        );
        return await response.json();
    }

    public async admin_send_user_invite(user_id: UUID): Promise<InviteCode> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/user/${user_id}/send-invite`,
        );
        return await response.json();
    }

    public async admin_send_account_invite(
        account_id: UUID,
    ): Promise<InviteCode> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/send-invite`,
        );
        return await response.json();
    }

    public async admin_deactivate_account(account_id: UUID): Promise<Account> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/deactivate`,
        );
        return await response.json();
    }

    public async admin_deactivate_user(user_id: UUID): Promise<User> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/user/${user_id}/deactivate`,
        );
        return await response.json();
    }

    public async admin_fetch_user_invite_codes(
        user_id: UUID,
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<InviteCode>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/user/${user_id}/invite-codes`,
            { params: getPaginationParams(pagination) },
        );
        return await response.json();
    }

    public async admin_create_user(
        account_id: UUID,
        invite_info: CreateUserData,
        options: Partial<{ send_invite: boolean }> = {},
    ): Promise<UserInvite> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/users`,
            {
                body: invite_info,
                params: options.send_invite
                    ? { send_code: String(options.send_invite) }
                    : undefined,
            },
        );
        return await response.json();
    }

    public async admin_fetch_account_settings(
        account_id: UUID,
    ): Promise<AccountSettings> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/account/${account_id}/settings`,
        );
        return await response.json();
    }

    public async admin_update_account_settings(
        account_id: UUID,
        settings: AccountSettings,
    ): Promise<AccountSettings> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/settings`,
            { body: settings },
        );
        return await response.json();
    }

    public async admin_promote_account_to_organization(
        account_id: UUID,
        name: string,
    ): Promise<Account> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/convert-to-organization`,
            { body: { name } },
        );
        return await response.json();
    }

    public async admin_fetch_reports(
        pagination: CursorPaginationParams,
        exclude_internal: boolean = false,
    ): Promise<CursorPage<{ report: Report; user: User }>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/dashboard/report-instructions`,
            {
                params: {
                    ...getPaginationParams(pagination),
                    exclude_internal: String(exclude_internal),
                },
            },
        );
        return await response.json();
    }

    public async admin_fetch_action_panel_messages(
        pagination: CursorPaginationParams,
        exclude_internal: boolean = false,
    ): Promise<
        CursorPage<{
            id: UUID;
            message_type: APActionType;
            content: string;
            status: APActionStatus;
            status_reason: string | null;
            created_at: string;
            user: User;
        }>
    > {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/dashboard/action-panel-message-list`,
            {
                params: {
                    ...getPaginationParams(pagination),
                    exclude_internal: String(exclude_internal),
                },
            },
        );
        return await response.json();
    }

    public async admin_new_default_conversation(
        message_id: UUID,
    ): Promise<UUID> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `message/${message_id}/conversations/default`,
            { body: { message_id } },
        );
        const json: { conversation_id: UUID } = await response.json();
        return json.conversation_id;
    }

    public async admin_fetch_products(): Promise<Product[]> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/payment/products`,
        );
        return await response.json();
    }

    public async admin_fetch_subscription(
        account_id: UUID,
    ): Promise<Subscription & { billing_details: BillingDetails }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `admin/account/${account_id}/subscription`,
        );
        return await response.json();
    }

    public async admin_promoto_to_paid(
        account_id: UUID,
        sub_info: SubscriptionInfo,
    ): Promise<SubscriptionInfo> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `admin/account/${account_id}/convert-to-paid`,
            { body: sub_info },
        );
        return await response.json();
    }

    public async fetch_account_settings(): Promise<AccountSettings> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `account/settings`,
        );
        return await response.json();
    }

    public async fetch_account_users(
        options: Partial<{
            sorting: Sorting | null;
            active: boolean | null;
            pagination?: CursorPaginationParams;
        }> = {},
    ): Promise<CursorPage<User>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `account/users`,
            {
                params: {
                    ...getPaginationParams(options.pagination),
                    ...options.sorting,
                    ...(options.active != null
                        ? { active: String(options.active) }
                        : undefined),
                },
            },
        );
        return await response.json();
    }

    public async create_user(
        invite_info: CreateUserData,
        options: Partial<{ send_invite: boolean }> = {},
    ): Promise<UserInvite> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `account/users`,
            {
                body: invite_info,
                params: options.send_invite
                    ? { send_code: String(options.send_invite) }
                    : undefined,
            },
        );
        return await response.json();
    }

    public async deactivate_user(user_id: UUID): Promise<User> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `account/users/${user_id}/deactivate`,
        );
        return await response.json();
    }

    public async fetch_user_invite_codes(
        user_id: UUID,
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<InviteCode>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `account/users/${user_id}/invite-codes`,
            { params: getPaginationParams(pagination) },
        );
        return await response.json();
    }

    public async send_user_invite(user_id: UUID): Promise<InviteCode> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `account/users/${user_id}/send-invite`,
        );
        return await response.json();
    }

    public async send_feedback(
        response_message_id: UUID,
        feedback: FeedbackData,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.POST,
            `feedback/${response_message_id}`,
            { body: feedback },
        );
    }

    public async fetch_converstation_ids(
        response_message_id: UUID,
    ): Promise<UUID[]> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `message/${response_message_id}/conversations`,
        );
        return await response.json();
    }

    public async fetch_nux_state(): Promise<Map<UUID, string>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "nux",
        );
        return new Map(Object.entries(await response.json()));
    }

    public async mark_nux_seen(nux_id: UUID): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.POST,
            `nux/${nux_id}/seen`,
        );
    }

    public async reset_nux(nux_id: UUID | null): Promise<number> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "nux/reset",
            { body: { nux_id } },
        );
        return await response.json();
    }

    public async upload_files(
        files: { id: UUID; file: File }[],
    ): Promise<Record<string, FileUploadResult>> {
        const formData = new FormData();
        files.forEach(({ file, id }) => {
            formData.append("upload_ids", id);
            formData.append("files", file);
        });
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `document/upload/files`,
            {
                omitContentType: true,
                body: formData,
            },
        );
        return await response.json();
    }

    public async delete_file(upload_id: UUID): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `document/upload/${upload_id}`,
        );
    }

    public async fetch_conversation_id(message_id: UUID): Promise<UUID> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `message/${message_id}/conversations/default`,
        );
        const data: { conversation_id: UUID; created: boolean } =
            await response.json();
        return data.conversation_id;
    }

    public async fetch_subscription(): Promise<Subscription> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/subscription",
        );
        return await response.json();
    }

    public async confirm_subscription(
        billing_details: BillingDetails | null = null,
    ): Promise<{
        customer_status: CustomerStatus;
        payment_intent_client_secret: string;
    }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "account/billing/subscription/confirm",
            { body: billing_details },
        );
        return await response.json();
    }

    public async fetch_stripe_client_secret(): Promise<string> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/subscription/payment-intent",
        );
        const json = (await response.json()) as { secret: string };
        return json.secret;
    }

    public async fetch_billing_info(): Promise<BillingDetails> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/info",
        );
        return await response.json();
    }

    public async update_billing_info(
        billing_details: BillingDetailsData,
    ): Promise<{
        customer_status: CustomerStatus;
        billing_details: BillingDetails;
    }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "account/billing/info",
            { body: billing_details },
        );
        return await response.json();
    }

    public async fetch_default_payment_method(): Promise<PaymentMethod> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/payment-methods/default",
        );
        return await response.json();
    }

    public async fetch_payment_methods(
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<PaymentMethod>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/payment-methods",
            { params: getPaginationParams(pagination) },
        );
        const data = (await response.json()) as {
            methods: PaymentMethod[];
            has_more: boolean;
            last_id: string | null;
        };
        return {
            items: data.methods,
            count: -1,
            page_info: {
                has_next_page: data.has_more,
                end_cursor: data.last_id,
            },
        };
    }

    public async fetch_payment_method_client_secret(): Promise<string> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "account/billing/payment-methods",
        );
        return (await response.json()).secret as string;
    }

    public async delete_payment_method(
        payment_method_id: string,
    ): Promise<boolean> {
        const response = await this.send_authenticated_request(
            HTTPMethod.DELETE,
            "account/billing/payment-methods",
            { body: { payment_method_id } },
        );
        return await response.json();
    }

    public async set_payment_default(
        payment_method_id: string,
        payment_method_type: string,
    ): Promise<{
        previous_payment_method: string | null;
        new_payment_method: string;
    }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `account/billing/payment-methods/default`,
            { body: { payment_method_id, payment_method_type } },
        );
        return await response.json();
    }

    public async fetch_invoices(
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<Invoice>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "account/billing/invoices",
            { params: getPaginationParams(pagination) },
        );
        const data = (await response.json()) as {
            invoices: Invoice[];
            has_more: boolean;
            last_id: string | null;
        };
        return {
            items: data.invoices,
            count: -1,
            page_info: {
                has_next_page: data.has_more,
                end_cursor: data.last_id,
            },
        };
    }

    public async pay_invoice(
        invoice_id: string,
        payment_method_id: string,
    ): Promise<Invoice> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "account/billing/invoices/pay",
            { body: { invoice_id, payment_method_id } },
        );
        return await response.json();
    }

    public async find_companies(query: string): Promise<Company[]> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "data/companies",
            { params: { query } },
        );
        return await response.json();
    }

    public async fetch_company_events(
        ticker: string,
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<APIContextItem>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `data/events`,
            { params: { ticker, ...getPaginationParams(pagination) } },
        );
        return await response.json();
    }

    public async fetch_multi_company_events(
        tickers: string[],
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<APIContextItem>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `data/multi_events`,
            {
                params: paramsToTuple({
                    tickers,
                    ...getPaginationParams(pagination),
                }),
            },
        );
        return await response.json();
    }

    public async fetch_action_history(
        message_id: UUID,
        conversation_id: UUID,
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<APActionBase>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `message/${message_id}/actions/${conversation_id}/history`,
            { params: getPaginationParams(pagination) },
        );
        return await response.json();
    }

    public async fetch_report_list(
        pagination?: CursorPaginationParams,
    ): Promise<CursorPage<DocumentCollection>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "report/light/list",
            { params: getPaginationParams(pagination) },
        );
        return await response.json();
    }

    public async create_report(
        context_items: (
            | EncodedContextItem
            | UserUploadContextItem
            | ExistingDocumentContextItem
        )[],
    ): Promise<FullDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "report/light",
            { body: { context_items } },
        );
        return await response.json();
    }

    public async create_search_action(
        query: string,
    ): Promise<FullDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "report/light/create-search",
            { body: { query } },
        );
        return await response.json();
    }
    public async confirm_search_action(
        body: ConfirmIntentPayload,
    ): Promise<FullDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "report/light/execute-search",
            { body },
        );
        return await response.json();
    }

    public async run_document_search_action(
        action_id: UUID,
        message_id: UUID,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.POST,
            "report/light/run-search",
            { body: { action_id, message_id } },
        );
    }

    public async update_report_title(
        message_id: UUID,
        title: string,
    ): Promise<BaseDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.PUT,
            `report/light/${message_id}`,
            { body: { title } },
        );
        return await response.json();
    }

    public async fetch_report(reportID: UUID): Promise<FullDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `report/light/${reportID}`,
        );
        return await response.json();
    }

    public async delete_report(reportID: UUID): Promise<boolean> {
        const response = await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `report/${reportID}`,
        );
        return await response.json();
    }

    public async report_add_documents(
        reportID: UUID,
        context_items: (
            | EncodedContextItem
            | UserUploadContextItem
            | ExistingDocumentContextItem
        )[],
    ): Promise<FullDocumentCollection> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `report/light/${reportID}/documents`,
            { body: { context_items } },
        );
        return await response.json();
    }

    public async fetch_document(
        reportID: UUID,
        documentID: UUID,
    ): Promise<FullDecoratedDocument> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `report/${reportID}/documents/${documentID}`,
        );
        return await response.json();
    }

    public async report_remove_documents(
        reportID: UUID,
        documentID: UUID,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `report/light/${reportID}/documents/${documentID}`,
        );
    }

    public async report_invalidate_document(
        reportID: UUID,
        documentID: UUID,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `report/${reportID}/${documentID}/invalidate-by-doc`,
        );
    }

    public async report_invalidate_by_finding_group(
        reportID: UUID,
        findingGroup: FindingGroupType,
        probeID?: UUID,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `report/${reportID}/${findingGroup}/invalidate-by-type${probeID ? "?probe_id=" + probeID : ""}`,
        );
    }

    public async fetch_search_companies(
        query: string,
    ): Promise<CompanySearchResult> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "search/companies",
            {
                body: JSON.stringify(query),
            },
        );
        return await response.json();
    }

    public async fetch_search_documents(
        query: string,
    ): Promise<DocumentSearchResult> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            "search/documents",
            {
                body: JSON.stringify(query),
            },
        );
        return await response.json();
    }

    public async fetch_citation(
        citation_id: UUID,
        retry: boolean = false,
    ): Promise<{ citation: string | null }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `message/citations/${citation_id}`,
            retry ? { params: { retry: "true" } } : undefined,
        );
        return { citation: await response.json() };
    }

    public async fetch_grid_view_state(
        message_id: UUID,
    ): Promise<{ version: number; state: Record<string, unknown> } | null> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `report/light/${message_id}/grid_view_state`,
        );
        return await response.json();
    }

    public async update_grid_view_state(
        message_id: UUID,
        state: VersionedState<Record<string, unknown>>,
    ): Promise<VersionedState<Record<string, unknown>>> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `report/light/${message_id}/grid_view_state`,
            { body: state },
        );
        return await response.json();
    }

    public async validate_probe(probe: {
        name: string;
        prompt: string;
        type: ProbeType;
    }): Promise<{
        name: string;
        user_prompt: string;
        clean_prompt: string;
        type: ProbeType;
    }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `probe/validate`,
            { body: probe },
        );
        return await response.json();
    }

    public async fetch_available_columns(): Promise<ColumnDefinition[]> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            "report/available-columns",
        );
        const json = (await response.json()) as { columns: ColumnDefinition[] };
        return json.columns;
    }

    public async fetch_finding_groups_for_column(
        message_id: UUID,
        finding_group_type: FindingGroupType,
        probe_id: UUID | undefined = undefined,
    ): Promise<{ [id: UUID]: FindingGroupInfo }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `report/${message_id}/columns/finding-groups`,
            {
                params: {
                    finding_group_type,
                    ...(probe_id !== undefined ? { probe_id } : undefined),
                },
            },
        );
        return await response.json();
    }

    public async retry_failed_finding_group(
        report_id: UUID,
        finding_group_id: UUID,
    ): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.DELETE,
            `report/${report_id}/${finding_group_id}/retry-failed`,
        );
    }

    public async report_add_column(
        message_id: UUID,
        column: AddColumnPayload,
    ): Promise<{ added: Column; columns: Column[] }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `report/${message_id}/columns`,
            { body: column },
        );
        return await response.json();
    }

    public async report_update_column(
        message_id: UUID,
        probe_id: UUID,
        payload: UpdateProbePayload,
    ): Promise<{ added: Column; removed: Column; columns: Column[] }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.PUT,
            `report/${message_id}/columns/${probe_id}`,
            { body: payload },
        );
        return await response.json();
    }

    public async report_remove_column(
        message_id: UUID,
        column: RemoveColumnPayload,
    ): Promise<{ removed: Column; columns: Column[] }> {
        const response = await this.send_authenticated_request(
            HTTPMethod.POST,
            `report/${message_id}/columns/remove`,
            { body: column },
        );
        return await response.json();
    }

    public async app_version(): Promise<VersionDetails> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `version`,
        );
        return await response.json();
    }

    public async fetch_probes(): Promise<Probe[]> {
        const response = await this.send_authenticated_request(
            HTTPMethod.GET,
            `probe`,
        );
        return await response.json();
    }

    public async hide_probe(schema_id: UUID): Promise<void> {
        await this.send_authenticated_request(
            HTTPMethod.PUT,
            `probe/${schema_id}/visibility`,
            { body: { hidden: true } },
        );
    }
}
